import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

import {  getAdminUser,DateTimeHHmmssDisplay } from '../../utils/common'
import { getOrgUserAccount,ApiApproveRequest } from '../../redux/actions/organizations'

import SearchText from '../../components/form/SearchText'
import Button from '../../components/form/button/Button'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import TableV2 from '../../components/common/TableV2'
import Field from '../../components/form/Field'
import TextField from '../../components/form/TextField'
import Dropdown from '../../components/form/Dropdown'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import DialogFail from '../../components/dialog/DialogFail'
import DialogConfirmV2 from '../../components/dialog/DialogConfirmV2'
import RadioButton from '../../components/form/RadioButton'
import SidePanel from '../../components/common/SidePanel'
import EditUserAccount from '../../pages/organization/EditUserAccount'
import NewUserAccount from '../../pages/organization/NewUserAccount'
import SwitchButton from '../../components/form/SwitchButton'


import iconPlus from '../../assets/images/icon-plus.svg'
import iconEdit from '../../assets/images/edit-icon.png'
import iconDelete from '../../assets/images/icon-bin-red.svg'
import { FaBullseye } from 'react-icons/fa'
import { GrStatusGoodSmall } from 'react-icons/gr'
import { BsCheck2Circle } from 'react-icons/bs'
import warningIcon from '../../assets/images/warning-icon.png'
import successIcon from '../../assets/images/success-icon.png'
import failIcon from '../../assets/images/fail-icon.png'


const Div = styled.div`

  .content-title {
    font-size: 36px;
    font-weight: 600;
    color: var(--Base-Font-Title-Color);
  }
  .content-title-text {
    color: var(--Base-Font-Title-SUB-Color);
    font-size: 18px;
  }
  .border_header {
    border-bottom: 1px solid rgb(234, 236, 240);
    margin-bottom: 21px;
    margin-top: 21px;
  }
  .content-fillter-bg {
    min-height: 68px;
    background-color: var(--Gray-50);
    border-radius: 8px;
    margin-bottom: 21px;
    padding: 10px;
  }
  .content-fillter {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    .search-wrapper {
      margin-right: 12px;
    }

    .filter-list {
      flex-basis: 100%;
      margin-top: 24px;
    }

    .group_filter {
      display: flex;
      align-items: center;
    }
  }

  .table-wrapper {
    margin-top: 24px;
    border: 1px solid #eaecf0;
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
    background-color: #ffffff;

    .table-header {
      display: flex;
      justify-content: space-between;
      padding: 16px 14px;
      border-radius: 8px 8px 0 0;
      background: var(--Table-Bg-Header);

      .group {
        display: flex;
        align-items: center;
      }

      .table-title {
        font-size: 18px;
        font-weight: 600;
        color: var(--Gray-900);
      }

      .table-total {
        margin-left: 16px;
        font-size: 12px;
        font-weight: 500;
        padding: 2px 8px;
        color: var(--BADGE-Table-Font);
        background: var(--BADGE-Table-Bg);
        border-radius: 16px;
        border: 1px var(--BADGE-Table-Border) solid;
      }
    }

    .create-bt {
      text-decoration: none;
    }
  }
  .cursor_pointer {
    cursor: pointer;
  }
  .css_magin_top_10 {
    margin-top: 10px;
  }
  .css_filter_name {
    min-width: 450px;
    margin-right: 10px;
  }
  .mr-1-rem {
    margin-right: 1rem;
  }
  .css_button_filter{
    display: inline-block;
    align-self: flex-end;
  }
  .button_create {
    color: #ffffff;
    background-color: #17b26a;
    border: 1px solid #17b26a;
  }
  .button_request_approve {
    color: #D21404;
    background-color: #ffffff;
    border: 1px solid #FDA29B;
  }
  .status-badge {
    width: 90px;
    padding: 0.2rem;
    text-align: center;
    border: 1.5px solid var(--Gray-300);
    border-radius: 6px;
    font-weight: 500;

    .status {
      font-size: 0.6rem;
      margin-right: 0.2rem;
      color: #17b26a;

      &.deny {
        color: red;
      }
      &.request {
        color: #7f56d9;
      }
      &.pedding{
        color: #475467;
      }
    }
  }
  .accessRequest-active{
    font-size: 20px;
    color: #17b26a;
  }
  .accessRequest-notactive{
    font-size: 20px;
    color: #475467;
  }
`



const OrgUserAccount = () => {
  const dispatch = useDispatch()
  const user = getAdminUser()

  const [UserAccountId, setUserAccountId] = useState(null)
  const [filterName, setfilterName] = useState('')
  const [filterStatus, setfilterStatus] = useState('')
  const status_option = [
    { text: 'Request', value: 3 },
    { text: 'Active', value: 1 },
    { text: 'Deny', value: 2 },
  ]
  const initLangRadioOptionList = [
    {
      text: '',
      value: true,
    },
  ]


  // modal
  
  const [ModalNewUser, setModalNewUser] = useState(false)
  const [ModalEditUser, setModalEditUser] = useState(false)
  const [DataEdit, setDataEdit] = useState('')

  const [ModalCreate, setModalCreate] = useState(false)
  const [ModalConfirmDel, setModalConfirmDel] = useState(false)
  const [ModalEditData, setModalEditData] = useState(false)
  const [ModalViewActionPlan, setModalViewActionPlan] = useState(false)
  const initMessageModal = {
    headline: '',
    message: '',
  }
  const [ModalConfirmDialog, setModalConfirmDialog] = useState(false)
  const [ModalConfirmDialogDel, setModalConfirmDialogDel] = useState(false)
  const [ConfirmMessageModal, setConfirmMessageModal] = useState(initMessageModal)

  const [successModal, setSuccessModal] = useState(false)
  const [failModal, setFailModal] = useState(false)
  const [successMessageModal, setSuccessMessageModal] = useState(initMessageModal)
  const [failMessageModal, setFailMessageModal] = useState(initMessageModal)

  const limit_data = 10;
  const [total, settotal] = useState(0)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [TableCellData, setTableCellData] = useState([])
  const TableColumnData = [
    {
      Header: 'ลำดับ',
      accessor: 'no',
      disableSortBy: false,
    },
    {
      Header: 'ชื่อผู้ใช้',
      accessor: 'full_name',
      disableSortBy: false,
    },
    {
      Header: 'เบอร์โทร',
      accessor: 'phone_no',
      disableSortBy: false,
    },
    {
      Header: 'Email',
      accessor: 'email',
      disableSortBy: false,
    },
    {
      Header: 'ตั้งค่าบัญชีหลัก',
      accessor: 'is_main_acount',
      disableSortBy: false,
    },
    {
      Header: 'สถานะ',
      accessor: 'status_name',
      disableSortBy: false,
    },
    {
      Header: 'เข้าสู่ระบบล่าสุด',
      accessor: 'last_login_date',
      disableSortBy: false,
    },
    {
      accessor: 'buttonDelete',
      disableSortBy: true,
    },
    {
      accessor: 'linkEdit',
      disableSortBy: true,
    },
    {
      accessor: 'accessRequest',
      disableSortBy: true,
    },
  ]

  const fetchData = useCallback(
    async (filter) => {
      dispatch(getOrgUserAccount(filter)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
          if (data_info.total > 0) {
            const totalPage = Math.ceil(data_info.total / limit_data)
            settotal(data_info.total)
            setTotalPages(totalPage)

            const newTableCellData = data_info.data.map((v, index) => ({
              no: limit_data * page - (9 - index),
              full_name: v.full_name ? v.full_name : '-',
              phone_no: v.phone_no ? v.phone_no : '-',
              email: v.email ? v.email : '-',
              //is_main_acount: v.is_main_acount ? v.is_main_acount : '-',
              /*
              is_main_acount: (
                <RadioButton
                  name="doc_lang"
                  optionList={initLangRadioOptionList}
                  value={v.is_main_acount}
                  handleChange={(event) => onChangeRadioMain(v.id, event)}
                  disabled={false}
                />
              ),
              */
              is_main_acount : (
                <SwitchButton
                  value={true}
                  defaultChecked={v.is_main_acount}
                  handleChange={(event) => onChangeRadioMain(v.id, event)}
                  disabled={false}
                />
              ),
              status_name: (
                <div className="status-badge">
                  <GrStatusGoodSmall className={`status ${v.status_id == 2 ? 'deny' : v.status_id == 3 ? 'request' : v.status_id == 4 ? 'pedding' : ''}`} />
                  {v.status_name}
                </div>
              ),
              /*
              accessRequest: (
                v.status_id == 3 ? <div className="accessRequest-active cursor_pointer" onClick={() => ApproveRequest(v.id)}>
                  <BsCheck2Circle />
                </div> : <div className="accessRequest-notactive">
                  <BsCheck2Circle />
                </div>
              ),
              */
              last_login_date: v.last_login_date ? DateTimeHHmmssDisplay(v.last_login_date) : '-',
              accessRequest: (
                v.status_id == 3 ? <div >
                  <ButtonOutline onClick={() => ApproveRequest(v.id)} className="button_request_approve">
                    ขอเข้าสังกัด
                  </ButtonOutline>
                </div> : <div ></div>
              ),
              
              buttonDelete: <img className="cursor_pointer" src={iconDelete} title="Delete" onClick={() => DelData(v.id)} />,
              linkEdit: <img className="cursor_pointer" src={iconEdit} title="Edit" onClick={() => EditData(v)} />,
            }))
            setTableCellData(newTableCellData)
          }else{
            settotal(0);
            setPage(0);
            setTotalPages(0);
            setTableCellData([]);
          }
         
        }
      })
    },
    [dispatch]
  )

  useEffect(() => {
    const filter = {
      skip: 0,
      limit: limit_data,
    }
    fetchData(filter)
  }, [fetchData, limit_data])

  const onChangePaginationAttendeeAttend = async (value) => {
    const filter = {
      skip: (value.page  - 1) * limit_data, 
      limit: limit_data,
    }
    fetchData(filter)
    setPage(value.page)
  }
  const onClearfilterName = () => {
    setfilterName('');
  }
  const onSearch = () => {
    const filter = {
      skip: 0,
      limit: limit_data,
      name : filterName,
      status_id : filterStatus,
    }
    fetchData(filter)
    setPage(1)
  }
  const onClearSearch = () => {
    setfilterName('')
    setfilterStatus('')
    const filter = {
      skip: 0,
      limit: limit_data,
    }
    fetchData(filter)
    
    setPage(1)
  }
  const ApproveRequest = (data) => {
    setUserAccountId(data)
    setConfirmMessageModal({
        headline: 'Approve Request',
        message: 'อนุมัติคำขอเข้าเป็นผู้ใช้งานภายใต้แม่ข่าย',
    })
    setModalConfirmDialog(true);
  }
  const DelData = (data) => {
    setUserAccountId(data)
    setConfirmMessageModal({
        headline: 'กรุณายืนยันการลบบัญชีผู้ใช้นี้',
        message: '',
    })
    setModalConfirmDialogDel(true);
  }
  const EditData = (data) => {
    //alert('EditData --> ' + data)
    //setUserAccountId(data)
    setDataEdit(data);
    setModalEditUser(true)
  }
  const onChangeRadioMain = (id,event) => {
    //const { name, value } = event.target;
    if(event.target.checked){
      const request = {
          org_user_account: id ,
          type_approve_id : -99
      }
      dispatch(ApiApproveRequest(request)).then(({ type,data_info }) => {
        
        if(type.endsWith('_SUCCESS')){
          if(data_info.code == 200){
            onClearSearch();
          }
        }
        
      })
    }
  }
  const OpenCreateModal = () => {
    setModalNewUser(true)
  }

  const handleApproveRequest = (type_approve_id) => {
    setModalConfirmDialog(false)
    

    const request = {
      org_user_account: UserAccountId ,
      type_approve_id : type_approve_id
  }
  dispatch(ApiApproveRequest(request)).then(({ type,data_info }) => {
    
    if(type.endsWith('_SUCCESS')){
      if(data_info.code == 200){
        if(type_approve_id == 1){
          setSuccessMessageModal({
            headline: 'อนุมัติรายการสำเร็จ',
            message: '',
          })
          setSuccessModal(true)
        }else if(type_approve_id == 0){ //--- ลบ USER
          setSuccessMessageModal({
            headline: 'ลบรายการสำเร็จ',
            message: '',
          })
          setSuccessModal(true)
        }else{
          setFailMessageModal({
            headline: 'ปฏิเสธรายการสำเร็จ',
            message: '',
          })
          setFailModal(true)
        }
        

      }else{
        
          setFailMessageModal({
              headline: data_info.message,
              message: '',
          })
          setFailModal(true)
        
      }
    }else{
      setFailMessageModal({
        headline: 'internal server error',
        message: '',
      })
      setFailModal(true)
    }
    
  })

    
  }


  return (
    <Div>
      <header>
        <div className="content-title">
          จัดการบัญชีผู้ใช้องค์กร
        </div>
        <div className="content-title-text">
          {user?.organizations_name ? user?.organizations_name : '-'}
        </div>
        <div className="border_header"></div>

        <div className="content-fillter-bg">
          <div className="content-fillter">
            <div className="group_filter">
              <div className="css_filter_name">
                <Field className="field" label="ค้นหาด้วยชื่อหรืออีเมล์">
                  <SearchText
                    placeholder="ค้นหาด้วยชื่อหรืออีเมล์"
                    value={filterName}
                    onChange={(e) => setfilterName(e.target.value)}
                    onClear={onClearfilterName}
                  />
                </Field>
              </div>
              <div className="mr-1-rem">
                <Field className="field" label="สถานะ">
                  <Dropdown
                      id={`dd_filterStatus`}
                      className="dd_filterStatus"
                      value={filterStatus}
                      optionList={status_option}
                      onChange={(v) => setfilterStatus(v)}
                      placeHolder={'เลือกสถานะ'}
                  />
                </Field>
              </div>
              <div className="css_button_filter mr-1-rem">
                  <Button onClick={onSearch} >
                    ค้นหา
                  </Button>
              </div>
              <div className="css_button_filter mr-1-rem">
                <ButtonOutline onClick={onClearSearch} >
                  ล้างการค้นหา
                </ButtonOutline>
              </div>
            </div>
            {user?.is_main_acount && (
              <div className="css_button_filter">
                <Button append={<img src={iconPlus} />} onClick={OpenCreateModal} className="button_create">
                  เพิ่มบัญชีผู้ใช้
                </Button>
              </div>
            )}
          </div>
        </div>

        <div className="table-wrapper">
          <div className="table-header">
            <div className="group">
              <div className="table-title">ชื่อผู้ใช้ทั้งหมด</div>
              <div className="table-total">
                {total} users
              </div>
            </div>
          </div>
          <div className="css_magin_top_10">
            <TableV2
              columns={TableColumnData}
              data={TableCellData}
              onStateChange={onChangePaginationAttendeeAttend}
              pageCount={totalPages}
              page={page}
              loading={false}
            />
          </div>
        </div>
      </header>
      
      <SidePanel isOpen={ModalNewUser} setIsOpen={setModalNewUser} width={400}>
            <NewUserAccount 
              width={400} 
              onClose={() => setModalNewUser(false)} 
              onSubmit={() => {
                setModalNewUser(false)
                onClearSearch()
              }}
            />
      </SidePanel>

      <SidePanel isOpen={ModalEditUser} setIsOpen={setModalEditUser} width={400}>
            <EditUserAccount 
              width={400} 
              onClose={() => setModalEditUser(false)} 
              onSubmit={() => {
                setModalEditUser(false)
                onClearSearch()
              }}
              DataEdit={DataEdit} 
            />
      </SidePanel>

      <DialogConfirmV2
        open={Boolean(ModalConfirmDialog)}
        onClose={() => setModalConfirmDialog(false)}
        onNo={() => handleApproveRequest(2)}
        onSubmit={() => handleApproveRequest(1)}
        icon={warningIcon}
        title={ConfirmMessageModal.headline}
        nameItem={ConfirmMessageModal.message}
        textYes='อนุมัติ'
        textNo='ปฏิเสธ'
      />

      <DialogConfirmV2
        open={Boolean(ModalConfirmDialogDel)}
        onClose={() => setModalConfirmDialogDel(false)}
        onNo={() => setModalConfirmDialogDel(false)}
        onSubmit={() => {
          setModalConfirmDialogDel(false)
          handleApproveRequest(0)
        }}
        icon={warningIcon}
        title={ConfirmMessageModal.headline}
        nameItem={ConfirmMessageModal.message}
        textYes='ยืนยัน'
        textNo='ยกเลิก'
      />

      {/* Dialog */}
      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => setSuccessModal(false)}
        onSubmit={() => {
          setSuccessModal(false)
          onClearSearch()
        }}
        icon={successIcon}
        title={successMessageModal.headline}
        description={successMessageModal.message}
        textYes='ตกลง'
      />
      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={() => {
          setFailModal(false)
          onClearSearch()
        }}
        icon={failIcon}
        title={failMessageModal.headline}
        description={failMessageModal.message}
        textYes='ตกลง'
      />

    </Div>
  )
}

export default OrgUserAccount
