import { useCallback, useEffect, useState,useRef } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate,useParams } from 'react-router-dom'

import { postAPI } from '../../utils/apiUpload'
import {  getAdminUser } from '../../utils/common'
import { ApiGetEngineeringCourse,ApiGetElearningPrototypeImg,getBranchEngineer } from '../../redux/actions/master'
import { ApiOrgElearning_profile,ApiUpdateOrgElearning,ApiUpdateStatusElearning } from '../../redux/actions/elearning'
import {  selectMaster } from 'redux/selectors'

import Button from '../../components/form/button/Button'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import ButtonSecondary from '../../components/form/button/ButtonSecondary'
import TextField from '../../components/form/TextField'
import Dropdown from '../../components/form/Dropdown'
import Field from '../../components/form/Field'
import InputTextArea from '../../components/widget/element/InputTextArea'
import UploadFile from '../../components/upload/UploadFile'
import ContentElerning from '../../pages/elearning/ContentElerning'
import ContentExam from '../../pages/elearning/ContentExam'
import Modal from '../../components/common/Modal'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import DialogFail from '../../components/dialog/DialogFail'
import ElearningPreview from '../../pages/elearning/ElearningPreview'
import SidePanel from '../../components/common/SidePanel'
import OutlineCheckbox from '../../components/form/OutlineCheckbox'
import Checkbox from '../../components/form/Checkbox'


import iconPlus from '../../assets/images/icon-plus.svg'
import iconEye from '../../assets/images/eye.png'
import logo_menu from '../../assets/images/logo_menu.png'
import { FaBullseye } from 'react-icons/fa'
import { GrStatusGoodSmall } from 'react-icons/gr'
import { GoPlus } from "react-icons/go";
import { IoIosLink } from "react-icons/io";
import { IoMdClose } from 'react-icons/io'
import { FaRegCircleCheck } from "react-icons/fa6";
import successIcon from '../../assets/images/success-icon.png'
import failIcon from '../../assets/images/fail-icon.png'
import ImgUpload from '../../assets/images/ImgUpload.png'
import PlusIcon from '../../assets/images/PlusIcon.png'
import { RiDeleteBinLine } from "react-icons/ri";



const Div = styled.div`
  .text_warning_duration{
    font-size: 14px;
    font-weight: 500;
  }
  .box_header{
    display: flex;
    justify-content: space-between;
  }
  .content-title {
    font-size: 36px;
    font-weight: 600;
    color: var(--Base-Font-Title-Color);
  }
  .content-title-text {
    color: var(--Base-Font-Title-SUB-Color);
    font-size: 18px;
  }
  .css_button_add{
    display: inline-block;
    align-self: flex-end;
  }
  .border_header {
    border-bottom: 1px solid rgb(234, 236, 240);
  }
  .status-badge {
    width: 150px;
    padding: 0.2rem;
    text-align: center;
    font-weight: 500;
    border-radius: 16px;
    color: #475467;
    background: #f5f5f4;
    border: 1px #e7e5e4 solid;

    &.expire {
      color:#B42318;
      background: #FEF3F2;
      border: 1px #FECDCA solid;
    }
    &.active {
      color:#067647;
      background: #ECFDF3;
      border: 1px #ABEFC6 solid;
    }

    .status {
      font-size: 0.6rem;
      margin-right: 0.2rem;
      color: #475467;
      

      &.expire {
        color:#B42318;
      }
      &.active {
        color:#067647;
      }

    }
  }

  .table-wrapper {
    margin-top: 24px;
    border: 1px solid #eaecf0;
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
    background-color: #ffffff;

    .table-header {
      display: flex;
      justify-content: space-between;
      padding: 16px 14px;
      border-radius: 8px 8px 0 0;
      background: var(--Table-Bg-Header);

      .group {
        display: flex;
        align-items: center;
      }

      .table-title {
        font-size: 18px;
        font-weight: 600;
        color: var(--Gray-900);
      }

      .table-total {
        margin-left: 16px;
        font-size: 12px;
        font-weight: 500;
        padding: 2px 8px;
        color: var(--BADGE-Table-Font);
        background: var(--BADGE-Table-Bg);
        border-radius: 16px;
        border: 1px var(--BADGE-Table-Border) solid;
      }
    }

    .create-bt {
      text-decoration: none;
    }
  }
  .css_magin_top_24 {
    margin-top: 24px;
  }
  .css_TextField {
    width: 100%;
  }
  .dialog-footer {
    text-align: end;
    right: 0px;
    bottom: 70px;
    background-color : #FFFFFF;
    position: absolute;
    width: 100%;
    padding: 0px 24px;
    .button_margin {
      margin: 10px;
    }
  }
  .button-footer{
    display: flex;
    justify-content: space-between;
  }
  .button_Draft {
    margin-top: 10px;
    color: var(--Base-Font-Focus-Color);
  }
  .InputTextArea_css {
    width: 100%;
    font-size: 14px;
  }
  .content_box {
    display: flex;
    align-items: flex-start;
    gap: 10px;
  }
  .css_dropdrow_engineering {
    min-width: 415px;
  }
  .css_full_box{
    /*overflow-y: auto;*/
    /*margin-bottom: 140px;*/
    margin-bottom: 100px;
  }
  .field_EngineeringCourse{
    min-width: 275px;
  }

  /*--- ST หมวดหมู่ --- */
  .button_EngineeringCourse{
    text-align: left;
  }
  .box_dropdown_EngineeringCourse{
    position: relative;
    width: 300px;
  }
  .dropdown_EngineeringCourse_none{
    display : none;
  }
  .dropdown_EngineeringCourse {
    position: absolute;
    z-index: 1000;
    padding: 24px;
    border: 1px solid rgba(0, 0, 0, 0.04);
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    font-size: 16px;
    font-style: normal;
    
    height: 400px;
    overflow-y: auto;

    .check_box_css{
      display : flex;
    }
    .text_category{
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
    }
  }

  /*--- ED หมวดหมู่ --- */

`

const DivTab = styled.div`
width: 100%;
margin-bottom: 1rem;
margin-top: 1rem;
display: flex;
/*border-bottom: 1px solid var(--Tab-Border-Default);*/

.btn-menu-style {
    border: none;
    padding: 10px 18px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    background-color: var(--Tab-Bg-Default);
    /*border-bottom: 0px solid var(--Tab-Border-Default);*/
    border-bottom: 2px solid var(--Tab-Border-Default);
    color: var(--Tab-Font-Default);  
    font-size: 14px;
    /*font-family: 'Inter','Noto Sans Thai';*/
    font-family: inherit;
}

.menu-active {
    border: none;
    padding: 10px 18px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 2px solid var(--Tab-Border-Current) !important;
    color: var(--Tab-Font-Current); 
    background-color: var(--Tab-Bg-Current);
    /*font-family: 'Inter','Noto Sans Thai';*/
    font-family: inherit;
}
`

const DivModalCoverImg = styled.div`
  padding: 24px 24px;
  min-width: 560px;
  .icon {
    cursor: pointer;
  }
  .header_coverimg{
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  .close-modal-content {
    display: flex;
    justify-content: flex-end;
  }
  .header_coverimg_data{
    display: flex;
    gap : 16px;
  }
  .modal_text_bold{
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
  }
  .modal_text_normal{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
  .border_header {
    border-bottom: 1px solid rgb(234, 236, 240);
  }
  .css_magin_top_20{
    margin-top: 24px;
  }
  .content_data{
    width: 512px;
    display: flex;
    justify-content: center;
    img {
      width: 512px;
      height: 296px;
    }
    .content_add_cover{
      img {
        width: 40px;
        height: 41px;
        cursor: pointer;
      }
      .img-cover {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
        cursor: pointer;
        &.active{
          box-shadow: 0px 0px 5px 4px #A91F23;
        }
      }
    }
  }
  .box_prototype_img{
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 10px;
  }

  .dialog-button {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;

    button {
      justify-content: center;
    }
  }
`
const HiddenInput = styled.input.attrs({ type: 'file' })`
  display: none;
`

const DivModalConfirm = styled.div`
  padding: 24px 46px;
  min-width: 500px;
  .icon {
    cursor: pointer;
  }

  .header_confirm{

  }
  .justify-content-center {
    display: flex;
    justify-content: center;
  }
  .justify-content-end {
    display: flex;
    justify-content: flex-end;
  }
  .justify-content-space-between {
    display: flex;
    justify-content: space-between;
  }
  .modal_text_bold{
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
  }
  .modal_text_normal{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
  .modal_text_input{
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
  .border_header {
    border-bottom: 1px solid rgb(234, 236, 240);
  }
  .css_magin_top_20{
    margin-top: 24px;
  }
  .content_confirm{
    display: grid;
    grid-template-columns: repeat(2, 40% [col-start] 60% [col-end]);
    gap: 10px;
    align-items: start;
    margin: 8px 0px;
  }
  .css_margin_0{
    margin:0px
  }
  .icon-check{
    color : var(--Base-Font-Focus-Color);
  }
  .content-topic{
    display: flex;
    /*
    justify-content: center;
    */
    align-items: center;
    line-height: 0px;
    gap: 10px;
  }
  .modal_text_topic_name{
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }
  .dialog-button {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;

    button {
      justify-content: center;
    }
  }
`

const DivBadgeEngineer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap : 10px;
  margin-top : 24px;
  .status-badge {
    width: fit-content;
    padding: 2px 6px;

    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;

    border-radius: 8px;
    color: #B42318;
    background: #FEF3F2;
    border: 1px #FECDCA solid;

    display: flex;
    align-items: center;

    .status {
      font-size: 18px;
      margin-left: 2px;
      color: #B42318;
      cursor: pointer;
    }
  }

  .clearAll {
    width: fit-content;
    padding: 2px 6px;

    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;

    border-radius: 8px;
    color: #475467;
    background: #FFFFFF;
    border: 1px #FFFFFF solid;

    display: flex;
    align-items: center;

    cursor: pointer;

    .status {
      font-size: 18px;
      margin-right: 2px;
      color: #475467;
      
    }
  }
`



const ElearningInfo = () => {
  const dispatch = useDispatch()
  const user = getAdminUser()
  const navigate = useNavigate()
  const { id } = useParams()

  
  const [isOpenData, setisOpenData] = useState(false)
  const [DataInfo, setDataInfo] = useState({
    topic_name : '',
    detail : '',
    engineering : null,
    hours : null,
    minute : null,
  })

  const [DataContent, setDataContent] = useState([])
  const [DataConfirm, setDataConfirm] = useState(null)
  const { branchEngineerCPDOptions } = useSelector(selectMaster)
  const [engineering_option, setengineering_option] = useState([])
  const [EngineeringCourseMainOption, setEngineeringCourseMainOption] = useState([])
  const [EngineeringCourse17Option, setEngineeringCourse17Option] = useState([])
  const [EngineeringCourseGeneralOption, setEngineeringCourseGeneralOption] = useState([])

  const fetchBranchEngineer = useCallback(() => {
    dispatch(getBranchEngineer())
  }, [dispatch])

  useEffect(() => {
    fetchBranchEngineer()
  }, [])

  useEffect(() => {
    const newBranchEngineerOptions = branchEngineerCPDOptions.map((v) => ({
      text: v.name_th_th,
      value: v.id.toString(),
    }))
    setengineering_option(newBranchEngineerOptions)
  }, [branchEngineerCPDOptions])


  useEffect(() => {
    dispatch(ApiGetEngineeringCourse()).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
            const temp_EngineeringCourseMain = data_info?.data?.filter((v) => v.category_type == 'วิศวกรรมควบคุม');
            const temp_EngineeringCourse17 = data_info?.data?.filter((v) => v.category_type == '17 สาขา');
            const temp_EngineeringCourseGeneral = data_info?.data?.filter((v) => v.category_type == 'หมวดทั่วไป');
            const newEngineeringCourseMain= temp_EngineeringCourseMain?.map((v) => ({
              text: v?.name_th_th,
              value: v?.id
            }))
            setEngineeringCourseMainOption([...newEngineeringCourseMain])
            const newEngineeringCourse17= temp_EngineeringCourse17?.map((v) => ({
              text: v?.name_th_th,
              value: v?.id
            }))
            setEngineeringCourse17Option([...newEngineeringCourse17])
            const newEngineeringCourseGeneral= temp_EngineeringCourseGeneral?.map((v) => ({
              text: v?.name_th_th,
              value: v?.id
            }))
            setEngineeringCourseGeneralOption([...newEngineeringCourseGeneral])
        }
    })
  }, [dispatch])

  const [PrototypeCoverImgOption, setPrototypeCoverImgOption] = useState([])
  useEffect(() => {
    dispatch(ApiGetElearningPrototypeImg()).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
            const newPrototypeCoverImgOption = data_info?.data?.map((v) => ({
              url_img: v?.url_img
            }))
            setPrototypeCoverImgOption([...newPrototypeCoverImgOption])
        }
    })
  }, [dispatch])

  

  const [isDivEngineeringCourse, setisDivEngineeringCourse] = useState(false)

  const onEngineeringCourse = () => {
    setisDivEngineeringCourse(isDivEngineeringCourse ? false : true)
  }

  const [engineeringFill, setengineeringFill] = useState([])

  useEffect(() => {
    const sent_data_to_api = {
        elearning_id : id,
    };
    dispatch(ApiOrgElearning_profile(sent_data_to_api)).then(({ type,data_info }) => {
      if(type.endsWith('_SUCCESS')){
        if(data_info.data_info){
          if(data_info.data_info.data_content){
            setDataContent(data_info.data_info.data_content);
          }
          setDataInfo(data_info.data_info);
          if(data_info.data_info?.engineering_data){
            const newEngineeringCourseMain= data_info.data_info?.engineering_data.map((v) => ({
              text: v?.name_th_th,
              value: v?.id
            }))
            setengineeringFill(newEngineeringCourseMain)
          }
          setisOpenData(true)
        }
      }
    })
  }, [dispatch])

  
  const onClearAllEngineer = () => {
    setengineeringFill([]);
    setDataInfo({ ...DataInfo, engineering_fill: null })
  }
  const handleChangeCheckBox = (e,inp_value,inp_text) => {
    if(e){
      const this_engineering_fill = DataInfo?.engineering_fill ? [...DataInfo?.engineering_fill, inp_value] : [inp_value]; 
      setDataInfo({ ...DataInfo, engineering_fill: this_engineering_fill })
      setengineeringFill([...engineeringFill,{ value : inp_value , text : inp_text}])
    }else{
      const this_engineering_fill = DataInfo?.engineering_fill.filter((v) => v !== inp_value)
      setDataInfo({ ...DataInfo, engineering_fill: this_engineering_fill })
      const this_engineeringFill = engineeringFill.filter((v) => v.value !== inp_value)
      setengineeringFill(this_engineeringFill)
    }
    
  }




  const onView = () => {
    alert('onView')
  }
  const onCancle = () => {
    navigate('/Activity/Elearning')
  }

  const [tabactive, setTabactive] = useState('Content')
  const [menuTabList, setMenuTabList] = useState([
    {
        name: 'เนื้อหา',
        value: 'Content',
        active_status: true,
    },
    {
        name: 'แบบทดสอบ',
        value: 'Exam',
        active_status: false,
    },
  ])
  const handleClickTaskMenuTab = (data) => {
    let tmpList = [...menuTabList]
    for (let i = 0; i < tmpList.length; i++) {
      if (tmpList[i].value == data.value) {
        tmpList[i].active_status = true
        setTabactive(tmpList[i].value)
      } else {
        tmpList[i].active_status = false
      }
    }
    setMenuTabList([...tmpList])
  }

  const [ModalCoverImg, setModalCoverImg] = useState(false)
  const [ModalConfirm, setModalConfirm] = useState(false)

  const [Titlesuccess, setTitlesuccess] = useState('')
  const [SubTitlesuccess, setSubTitlesuccess] = useState('')
  const [Descriptionsuccess, setDescriptionsuccess] = useState('')
  const [successModal, setsuccessModal] = useState(false)
  const initMessageModal = {
    headline: '',
    message: '',
  }
  const [failMessageModal, setFailMessageModal] = useState(initMessageModal)
  const [failModal, setFailModal] = useState(false)

  const [WidthSidePanel, setWidthSidePanel] = useState(window.innerWidth - 92)
  const [ModalPreview, setModalPreview] = useState(false)

  const onConfirm = () => {
    handleProcess('CoverImg');
    /* 15/05/2024 ยกเลิกไม่ต้องบังคับมีข้อสอบ
    const num_vdo = DataContent ? DataContent.filter((v) => v.type_content == 'vdo').length : 0;
    if(num_vdo > 0){ //--- กรณีเป็น บทความที่มี vdo ต้องมีแบบทดสอบ
      const sent_data_to_api = {
        elearning_id : id,
      };
      dispatch(ApiOrgElearning_profile(sent_data_to_api)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
          if(data_info.data_info){
            if(data_info.data_info?.exam_data){
              handleProcess('CoverImg');
            }else{
              setFailMessageModal({
                  headline: 'บทความนี้ต้องมีแบบทดสอบ',
                  message: '',
              })
              setFailModal(true)
            }
          }else{
            setFailMessageModal({
                headline: 'internal server error',
                message: '',
            })
            setFailModal(true)
          }
        }else{
          setFailMessageModal({
              headline: 'internal server error',
              message: '',
          })
          setFailModal(true)
        }
      })
    }else{
      handleProcess('CoverImg');
    }
    */
  }

  const closeModalConfirm = () => {
    setModalConfirm(false);
  }

  const closeModalCoverImg = () => {
    setModalCoverImg(false);
  }

  const inputFileCoverRef = useRef(null)
  const OpenInputCoverFile = () => {
    inputFileCoverRef.current.click()
  }

  const onChangeCoverImg = async (event) => {
    const file = event.target.files[0]
    const fetchList = []
    const formData = new FormData()
    formData.append('file', file)
    formData.append(
      'fileInfo',
      JSON.stringify({
        name: file.name,
        caption: file.name,
        alternativeText: file.name,
        folder: null,
      })
    )


    
    fetchList.push(
      postAPI({
        url: '/file/uploadformdata',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: null,
        },
        data: formData,
      })
    )

    const res = await Promise.all(fetchList)
    if(res.length > 0){
      if(res[0].url_file){
        setDataInfo({ ...DataInfo, cover_img: res[0].url_file })
        setPrototypeCoverImgOption([{ url_img : res[0].url_file} ,...PrototypeCoverImgOption])
      }
    }
  }

  const handleProcess = (type_goto) => {
    closeModalCoverImg();
    
    //---- ถ้าอนุมัตไปแล้วจะไม่อัพเดตค่าที่แก้
    const sent_data_to_api = DataInfo.status_id == 3 ? {} : {
        ...DataInfo,
        //engineering : DataInfo?.engineering_fill ? JSON.stringify(DataInfo?.engineering_fill).replace('[', '').replace(']', '') : null,
        status_id : DataInfo.status_id == 0 ? 1 : DataInfo.status_id,
        data_content : JSON.stringify(DataContent),
    };
    
    dispatch(ApiUpdateOrgElearning(id,sent_data_to_api)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
            if(data_info.code == 200){
                setDataConfirm(data_info.infoData);
                if(type_goto == 'Confirm'){
                    setModalConfirm(true);
                    
                }else if(type_goto == 'CoverImg'){
                  if(DataInfo?.cover_img){
                    const check_img = PrototypeCoverImgOption.filter((v) => v.url_img == DataInfo?.cover_img);
                    if(check_img.length == 0){
                      setPrototypeCoverImgOption([{ url_img : DataInfo?.cover_img} ,...PrototypeCoverImgOption])
                    }
                    
                  }else{ //-- SET ค่าแรกให้เป็นพื้นฐาน
                    setDataInfo({ ...DataInfo, cover_img: PrototypeCoverImgOption[0].url_img })
                  }
                  setModalCoverImg(true)
                }else if(type_goto == 'Preview'){
                    setModalPreview(true);
                }
            }else{
            
                setFailMessageModal({
                    headline: data_info.message,
                    message: '',
                })
                setFailModal(true)
            
            }
        }else{
                setFailMessageModal({
                headline: 'internal server error',
                message: '',
            })
            setFailModal(true)
        }   
    }) 
  }

  const ApproveProcess = () => {

    setModalConfirm(false);

    const sent_data_api = {
      elearning_id : id,
      status_id : 2
    };
    dispatch(ApiUpdateStatusElearning(sent_data_api)).then(({ type,data_info }) => {
      if(type.endsWith('_SUCCESS')){
        if(data_info.code == 200){
            setTitlesuccess('สร้างเนื้อหาสำเร็จแล้ว');
            setSubTitlesuccess('โปรดรอผลการอนุมัติจากสภาวิศวกร');
            setDescriptionsuccess(''); 
            setsuccessModal(true)
        }else{
            setFailMessageModal({
              headline: data_info.message,
              message: '',
            })
            setFailModal(true)
        }
      }else{
        setFailMessageModal({
          headline: 'internal server error',
          message: '',
        })
        setFailModal(true)
      }     
    })




  }

  

  return (
    <Div>
        <div className="css_full_box">
            {/* Header */}
            <div className="box_header">
                <div>
                    <div className="content-title">
                        บทความ
                    </div>
                    <div className="content-title-text">
                        ร่วมกันส่งต่อความรู้เพื่อส่งเสริมวิชาชีพได้ที่นี่
                    </div>
                </div>
                <div className="css_button_add">
                <ButtonSecondary append={<img src={iconEye} />} onClick={() => handleProcess('Preview')} >
                    ดูตัวอย่าง
                </ButtonSecondary>
                </div>
            </div>
            <div className="css_magin_top_24"></div>
            <div className="border_header"></div>
            {/* DataInfo Header */}
            <div className="css_magin_top_24"></div>
            <div >
                <Field className="field" label="ชื่อบทความ">
                    <TextField
                        name="topic_name"
                        className="css_TextField"
                        placeholder={'ระบุ'}
                        value={DataInfo.topic_name}
                        onChange={(e) => setDataInfo({ ...DataInfo, topic_name: e.target.value })}
                    />
                </Field>
            </div>
            <div className="css_magin_top_24"></div>
            <div > 
                <Field className="field" label="คำอธิบายโดยย่อ">
                    <InputTextArea
                        className="InputTextArea_css"
                        initValue={DataInfo.detail}
                        onChange={(e) => setDataInfo({ ...DataInfo, detail: e.target.value  })}
                        placeHolder="กรุณาระบุ..."
                        borderColor={'var(--Gray-300)'}
                        borderWidth="1px"
                    />
                </Field>
            </div>
            <div className="css_magin_top_24"></div>
            <div className="content_box">
                <div >
                    {
                      /*
                      <Field className="field" label="หมวดหมู่ (เลือกได้มากกว่า 1)">
                          <Dropdown
                          id={`dd_filterUser`}
                          className="css_dropdrow_engineering"
                          value={DataInfo.engineering}
                          optionList={engineering_option}
                          onChange={(v) => setDataInfo({ ...DataInfo, engineering: v })}
                          placeHolder={'เลือก'}
                          ValueplaceHolderIsNull={true}
                          />
                      </Field>
                      */
                    }
                    {
                      /*
                      <Field className="field_EngineeringCourse" label="หมวดหมู่ (เลือกได้มากกว่า 1)">
                        <ButtonOutline  onClick={onEngineeringCourse} className="button_EngineeringCourse">
                          {`เลือกสาขาวิศวกรรม (${DataInfo?.engineering_fill ? DataInfo?.engineering_fill?.length : 0})`}
                        </ButtonOutline>
                      </Field>
                      */
                    }

                    <Field className="field_EngineeringCourse" label="สาขาวิศวกรรม">
                          <Dropdown
                            id={`dd_filterUser`}
                            className="css_dropdrow_engineering"
                            value={DataInfo.engineering}
                            optionList={engineering_option}
                            onChange={(v) => setDataInfo({ ...DataInfo, engineering: v })}
                            placeHolder={'เลือก'}
                            ValueplaceHolderIsNull={true}
                          />
                    </Field>
                    


                    <div className="box_dropdown_EngineeringCourse">
                      <div className={isDivEngineeringCourse ? 'dropdown_EngineeringCourse' : 'dropdown_EngineeringCourse_none'}>
                        <div className="text_category">วิศวกรรมควบคุม</div>
                        {EngineeringCourseMainOption?.map((item, index) => (
                          <div className="check_box_css">
                            <div>
                            <Checkbox checked={DataInfo?.engineering_fill?.filter((v) => v == item.value).length > 0 ? true : false} onChange={(e) => handleChangeCheckBox(e,item.value,item.text)} />
                            </div>
                            <div>
                              {item.text}
                            </div>
                          </div>
                        ))}   
                        <div className="text_category">วิศวกรรมไม่ควบคุม</div>
                        {EngineeringCourse17Option?.map((item_17, index_17) => (
                          <div className="check_box_css">
                            <div>
                              <Checkbox checked={DataInfo?.engineering_fill?.filter((v) => v == item_17.value).length > 0 ? true : false} onChange={(e) => handleChangeCheckBox(e,item_17.value,item_17.text)} />
                            </div>
                            <div>
                              {item_17.text}
                            </div>
                          </div>
                        ))} 
                        <div className="text_category">หมวดทั่วไป</div>
                        {EngineeringCourseGeneralOption?.map((item_general, index_general) => (
                          <div className="check_box_css">
                            <div>
                              <Checkbox checked={DataInfo?.engineering_fill?.filter((v) => v == item_general.value).length > 0 ? true : false} onChange={(e) => handleChangeCheckBox(e,item_general.value,item_general.text)} />
                            </div>
                            <div>
                              {item_general.text}
                            </div>
                          </div>
                        ))} 
                      </div>
                    </div>
                </div>
                <div >
                    <Field className="field" label="ระยะเวลาอ่าน">
                        <TextField
                            name="topic_name"
                            className="css_TextField"
                            placeholder={'ระบุ'}
                            value={DataInfo.hours}
                            endAdornment={<div >(ชั่วโมง)</div>}
                            onChange={(e) => setDataInfo({ ...DataInfo, hours: e.target.value })}
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                        />
                        <div className="text_warning_duration">
                          Maximum 3 ชั่วโมง
                        </div>
                    </Field>
                </div>
                <div >
                    <Field className="field" label="ระยะเวลาอ่าน">
                        <TextField
                            name="topic_name"
                            className="css_TextField"
                            placeholder={'ระบุ'}
                            value={DataInfo.minute}
                            endAdornment={<div >(นาที)</div>}
                            onChange={(e) => setDataInfo({ ...DataInfo, minute: e.target.value })}
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                        />
                    </Field>
                </div>
            </div>
            {
              /*
              {engineeringFill?.length > 0  && (      
                <DivBadgeEngineer>     
                  {engineeringFill?.map((item, index) => (                  
                    <div className="status-badge">
                      {item.text}
                      <IoMdClose  className="status" onClick={() => handleChangeCheckBox(false,item.value,item.text)}  />
                    </div>
                  ))} 
                  <div className="clearAll" onClick={onClearAllEngineer} >
                    <RiDeleteBinLine  className="status"   />
                    Clear all
                  </div>
                </DivBadgeEngineer>
              )}
              */
            }                


            <div className="css_magin_top_24"></div>
            <div className="border_header"></div>
            {/* Tab */}
            <div className="css_magin_top_24"></div>
            <DivTab>
                {menuTabList?.map((item, index) => (
                    <button
                    id={'this_name-' + item.value}
                    key={'btn-' + index}
                    className={item.active_status ? 'menu-active' : 'btn-menu-style'}
                    onClick={() => handleClickTaskMenuTab(item)}
                    >
                        {item.name}
                    </button>
                ))}
            </DivTab>
            {/* Content Per Tab */}

            {tabactive == 'Content' && (
                <div>
                    <ContentElerning DataContent={DataContent} setDataContent={setDataContent} />
                </div>
            )}
            {tabactive == 'Exam' && (
                <div>
                    <ContentExam elearning_id={id} engineering_fill={DataInfo.engineering_fill} />
                </div>
            )}

        </div>   
        {/* Button */}
        <div className="dialog-footer">
            <div className="border_header"></div>
            <div className="button-footer">
                <div>
                  {DataInfo.status_id != 3  && (  
                    <ButtonOutline className="button_Draft" onClick={() => handleProcess('Draft')}>
                        {'บันทึกฉบับร่าง'}
                    </ButtonOutline>
                  )}
                </div>
                <div>
                    <ButtonSecondary className="button_margin" onClick={onCancle}>
                        {'ยกเลิก'}
                    </ButtonSecondary>
                    {DataInfo.status_id != 3  && (  
                      <Button className="button_margin" onClick={onConfirm}>
                          {'เผยแพร่'}
                      </Button>
                    )}
                </div>
            </div>
        </div>
        <SidePanel isOpen={ModalPreview} setIsOpen={setModalPreview} width={WidthSidePanel}>
            <ElearningPreview 
              width={WidthSidePanel} 
              elearning_id={id}
              onClose={() => setModalPreview(false)} 
              onSubmit={() => setModalPreview(false)}
            />
        </SidePanel>
        <DialogSuccess
          open={Boolean(successModal)}
          onClose={() => setsuccessModal(false)}
          onSubmit={() => {
            setsuccessModal(false)
            onCancle()
          }}
          icon={successIcon}
          title={Titlesuccess}
          subtitle={SubTitlesuccess}
          description={Descriptionsuccess}
          html={
            <div>
              <div>
                ตรวจสอบสถานะได้ที่ <span className="css_text_focus">หน้าแรก</span>
              </div>
              <div>
                หากมีข้อสงสัยโปรดติดต่อ ศูนย์ช่วยเหลือ
              </div>
            </div>
          }
          textYes={'ตกลง'}
        />

        <DialogFail
            open={Boolean(failModal)}
            onClose={() => setFailModal(false)}
            onSubmit={() => {
                setFailModal(false)
            }}
            icon={failIcon}
            title={failMessageModal.headline}
            description={failMessageModal.message}
            textYes='ตกลง'
        />
        {/* ModalCoverImg */}
        <Modal open={ModalCoverImg} disableBackdropClick>
            <DivModalCoverImg>
              <div className="header_coverimg">
                  <div className="header_coverimg_data">
                    <div>
                      <img src={ImgUpload} alt="otp icon" />
                    </div>
                    <div>
                      <div className="modal_text_bold">
                        เลือกรูป หรืออัพโหลดรูปหน้าปกบทความ
                      </div>
                      <div className="modal_text_normal">
                        ขนาดไฟล์ที่แนะนำคือ 1600 x 480px 
                      </div>
                    </div>
                  </div>
                  <div className="close-modal-content">
                    <IoMdClose size={20} className="icon" onClick={closeModalCoverImg} />
                  </div>
              </div>
              <div className="css_magin_top_20"></div>
              <div className="content_data">
                <div>
                  {DataInfo?.cover_img && (
                    <img src={DataInfo?.cover_img } />
                  )}
                  <div className="css_magin_top_20"></div>
                  <div className="box_prototype_img">
                    {PrototypeCoverImgOption?.map((item, index) => (
                      <div className="content_add_cover">
                           <img className={`img-cover ${DataInfo?.cover_img == item.url_img ? 'active' : ''}`} src={item.url_img} onClick={() => setDataInfo({ ...DataInfo, cover_img: item.url_img })} />
                      </div>
                    ))}
                    <div className="content_add_cover">
                      <img src={PlusIcon} alt="Upload" onClick={OpenInputCoverFile} />
                    </div>
                  </div>

                  <HiddenInput ref={inputFileCoverRef} id="custom-file-input" onChange={onChangeCoverImg} />
                </div>               
              </div>
              <div className="css_magin_top_20"></div>
              <div className="border_header"></div>
              <div className="css_magin_top_20"></div>
              <div className="dialog-button">
                <ButtonSecondary onClick={closeModalCoverImg} >ยกเลิก</ButtonSecondary>
                <Button onClick={() => handleProcess('Confirm')}>ตกลง</Button>
              </div>
            </DivModalCoverImg>
        </Modal>
        {/* ModalConfirm */}
        <Modal open={ModalConfirm} disableBackdropClick>
              <DivModalConfirm>
                <div className="header_confirm">
                  <div className="justify-content-end">
                    <IoMdClose size={20} className="icon" onClick={closeModalConfirm} />
                  </div>
                  <div className="justify-content-center">
                    <img src={logo_menu} alt="otp icon" />
                  </div>
                  <div className="justify-content-center modal_text_bold">
                    ยืนยันการสร้างบทความ
                  </div>
                  <div className="justify-content-center modal_text_normal">
                    เมื่อตรวจสอบความถูดต้องแล้ว กด ‘ยืนยัน’ 
                  </div>
                  <div className="justify-content-center modal_text_normal">
                    เพื่อยื่นบทความนี้ให้สภาวิศวกรดำเนินการอนุมัติ
                  </div>
                </div>
                <div className="css_magin_top_20"></div>
                <div className="border_header"></div>
                <div className="css_magin_top_20"></div>
                {DataConfirm && (
                    <div>
                        <div className="modal_text_topic_name">
                            {DataConfirm.topic_name}
                        </div>
                        <div className="css_magin_top_20"></div>
                        {DataConfirm.engineering && (
                            <div className="content_confirm">
                                <div className="content-topic">
                                    <div>
                                    <FaRegCircleCheck size={20} className="icon-check"  />
                                    </div>
                                    <div className="modal_text_normal">หมวดหมู่</div>
                                </div>
                                <div className="modal_text_input">{DataConfirm.engineering}</div>
                            </div>
                        )}
                        {DataConfirm.time_duration && (
                            <div className="content_confirm">
                                <div className="content-topic">
                                    <div>
                                    <FaRegCircleCheck size={20} className="icon-check"  />
                                    </div>
                                    <div className="modal_text_normal">ระยะเวลาอ่าน</div>
                                </div>
                                <div className="modal_text_input">{DataConfirm.time_duration}</div>
                            </div>
                        )}
                        {DataConfirm.is_exam && (
                            <div className="content_confirm">
                                <div className="content-topic">
                                    <div>
                                    <FaRegCircleCheck size={20} className="icon-check"  />
                                    </div>
                                    <div className="modal_text_normal">แบบทดสอบ</div>
                                </div>
                                <div className="modal_text_input">{DataConfirm.is_exam}</div>
                            </div>
                        )}
                        {DataConfirm.num_exam_question && (
                            <div className="content_confirm">
                                <div className="content-topic">
                                    <div>
                                    <FaRegCircleCheck size={20} className="icon-check"  />
                                    </div>
                                    <div className="modal_text_normal">จำนวนข้อสอบ</div>
                                </div>
                                <div className="modal_text_input">{DataConfirm.num_exam_question}</div>
                            </div>
                        )}
                        {DataConfirm.criteria_receiving_points && (
                            <div className="content_confirm">
                                <div className="content-topic">
                                    <div>
                                    <FaRegCircleCheck size={20} className="icon-check"  />
                                    </div>
                                    <div className="modal_text_normal">เกณฑ์การได้รับคะแนน</div>
                                </div>
                                <div className="modal_text_input">{DataConfirm.criteria_receiving_points}</div>
                            </div>
                        )}
                        {DataConfirm.point_get_no_exam && (
                            <div className="content_confirm">
                                <div className="content-topic">
                                    <div>
                                    <FaRegCircleCheck size={20} className="icon-check"  />
                                    </div>
                                    <div className="modal_text_normal">คะแนนที่จะได้รับ</div>
                                </div>
                                <div className="modal_text_input">{DataConfirm.point_get_no_exam}</div>
                            </div>
                        )}
                        {DataConfirm.point_get && (
                            <div className="content_confirm">
                                <div className="content-topic">
                                    <div>
                                    <FaRegCircleCheck size={20} className="icon-check"  />
                                    </div>
                                    <div className="modal_text_normal">คะแนนที่จะได้รับ</div>
                                </div>
                                <div className="modal_text_input">
                                    <div>สอบผ่าน = {DataConfirm.point_get.point_pass} คะแนน</div>
                                    <div>สอบไม่ผ่าน = {DataConfirm.point_get.point_not_pass} คะแนน</div>                                   
                                </div>
                            </div>
                        )}
                        {DataConfirm.condition_exam_pass && (
                            <div className="content_confirm">
                                <div className="content-topic">
                                    <div>
                                    <FaRegCircleCheck size={20} className="icon-check"  />
                                    </div>
                                    <div className="modal_text_normal">เงื่อนไขการสอบผ่าน</div>
                                </div>
                                <div className="modal_text_input">{DataConfirm.condition_exam_pass}</div>
                            </div>
                        )}
                        {DataConfirm.score_criteria && (
                            <div className="content_confirm">
                                <div className="content-topic">
                                    <div>
                                    <FaRegCircleCheck size={20} className="icon-check"  />
                                    </div>
                                    <div className="modal_text_normal">การนับคะแนน</div>
                                </div>
                                <div >
                                    <div className="modal_text_input">เกณฑ์มาตรฐาน: </div>
                                    <div>
                                        <ul className="css_margin_0">
                                            <li>ตอบถูก = {DataConfirm.score_criteria.valid_answer_point} คะแนน</li>
                                            <li>ตอบผิด = {DataConfirm.score_criteria.invalid_answer_point} คะแนน</li>
                                            <li>ไม่ตอบ = {DataConfirm.score_criteria.no_answer_point} คะแนน</li>
                                        </ul>
                                    </div>                                   
                                </div>
                            </div>
                        )}
                    </div>
                )}

                <div className="css_magin_top_20"></div>
                <div className="border_header"></div>
                <div className="css_magin_top_20"></div>
                <div className="dialog-button">
                  <ButtonSecondary onClick={closeModalConfirm} >ยกเลิก</ButtonSecondary>
                  <Button onClick={ApproveProcess}>ยืนยัน</Button>
                </div>
              </DivModalConfirm>
        </Modal>
    </Div>
  )
}

export default ElearningInfo
