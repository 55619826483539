import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'
import { selectActivityCPD } from 'redux/selectors'
import { getInstructorNameList, getRegisteredNameList } from 'redux/actions/activityCpd'

import styled from 'styled-components'
import { Box } from '@mui/material'
import HeaderPage from 'components/containers/HeaderPage'
import TabNavigation from 'components/containers/TabNavigation'
import TabGroupMenu from 'components/containers/TabGroupMenu'

import { AiOutlineApartment } from 'react-icons/ai'
import EditActivityPart from 'components/feature/CpdManage/EditActivityPart'
import RegisteredNameListPart from 'components/feature/CpdManage/RegisteredNameListPart'
import InstructorNameListPart from 'components/feature/CpdManage/InstructorNameListPart'

const Div = styled.div`
  .left-content {
    width: 350px;
    margin-right: 2rem;

    .heading-info {
      color: var(--Gray-700);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
    }
    .sub-heading-info {
      color: var(--Gray-600);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
  .right-content {
    flex-grow: 1;
  }

  .input-heading {
    color: var(--Gray-700);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;

    margin-bottom: 6px;
  }
  .input-sub-heading {
    color: var(--Gray-700);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;

    margin-bottom: 6px;
  }
  .card-styled {
    width: 100%;
  }
  .w-100 {
    width: 100%;
  }

  .add-btn {
    color: var(--COE-Main-CI);
    border-color: var(--COE-Main-CI);
  }
`

function EditOrgActivity() {
  // external hook
  const dispatch = useDispatch()
  const { id } = useParams()

  // initiate data
  const fetchInstructorNameList = useCallback(
    (id, filter = { skip: 0, limit: 10 }) => {
      dispatch(getInstructorNameList(id, filter))
    },
    [dispatch]
  )
  const fetchRegisteredNameList = useCallback(
    (id, is_member_coe, filter = { skip: 0, limit: 10 }) => {
      dispatch(getRegisteredNameList(id, is_member_coe, filter))
    },
    [dispatch]
  )

  useEffect(() => {
    fetchInstructorNameList(id)
    fetchRegisteredNameList(id, true)
    fetchRegisteredNameList(id, false)
  }, [id])

  // total
  const { totalInstructor, totalCoEMember, totalNormalMember,activityInfo } = useSelector(selectActivityCPD)

  // tab navigation
  const tabNavigationOptions = [
    {
      tab: <AiOutlineApartment size={18} />,
      navigateTo: '',
    },
    {
      tab: 'กิจกรรมองค์กรแม่ข่าย',
      navigateTo: '/Activity/OrgActivity',
    },
    {
      tab: 'รายละเอียดกิจกรรมองค์กรแม่ข่าย',
      navigateTo: '',
    },
  ]

  // tab menu
  const initialTabMenuOptions = [
    {
      text: 'รายละเอียดกิจกรรม',
      value: 'activity-detail',
      number: null,
    },
    {
      text: 'รายชื่อวิทยากร',
      value: 'instructor-name',
      number: null,
    },
    {
      text: 'ชื่อผู้ลงทะเบียน',
      value: 'register-name',
      number: null,
    },
  ]
  const [tabMenuOptions, setTabMenuOptions] = useState([])
  const [tabMenu, setTabMenu] = useState('activity-detail')

  const [activityName, setactivityName] = useState('รายละเอียดกิจกรรมองค์กรแม่ข่าย')
  const [activityCode, setactivityCode] = useState('-')

  useEffect(() => {
    const newTabMenuOptions = initialTabMenuOptions.map((v) => ({
      ...v,
      number:
        v.value === 'instructor-name'
          ? totalInstructor
          : v.value === 'register-name'
          ? totalNormalMember + totalCoEMember
          : null,
    }))
    setTabMenuOptions(newTabMenuOptions)
    setactivityName(activityInfo.name)
    setactivityCode(activityInfo.code)
  }, [totalInstructor, totalCoEMember, totalNormalMember,activityInfo])

  return (
    <Div>
      <Box sx={{ marginBottom: '20px' }}>
        <TabNavigation options={tabNavigationOptions} />
      </Box>

      <Box sx={{ mb: 2 }}>
        <HeaderPage heading={activityName} subHeading={activityCode} />
      </Box>

      <Box sx={{ mb: 2 }}>
        <TabGroupMenu tabMenuOptions={tabMenuOptions} tabMenu={tabMenu} setTabMenu={setTabMenu} />
      </Box>

      {tabMenu === 'activity-detail' ? (
        <EditActivityPart />
      ) : tabMenu === 'instructor-name' ? (
        <InstructorNameListPart />
      ) : (
        <RegisteredNameListPart />
      )}
    </Div>
  )
}

export default EditOrgActivity
