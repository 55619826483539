import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate,useParams } from 'react-router-dom'

import {  getAdminUser,DisplayTableActivityName,DateDisplayDDMMBBB,DisplayTableActivityDescription,DateDisplay } from 'utils/common'
import { ApiApplicationsProfile,ApiGetApplicationDocument,ApiUpdateApplications,ApiGetOrgActivity } from '../../redux/actions/organizations'
import {  getActivityCpd } from '../../redux/actions/activityCpd'

import Button from '../../components/form/button/Button'
import ButtonSecondary from '../../components/form/button/ButtonSecondary'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import TableV2 from '../../components/common/TableV2'
import TextField from '../../components/form/TextField'
import Dropdown from '../../components/form/Dropdown'
import RadioButton from '../../components/form/RadioButton'
import InputTextArea from '../../components/widget/element/InputTextArea'
import Field from '../../components/form/Field'
import Date from '../../components/form/Date'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import DialogFail from '../../components/dialog/DialogFail'
import DialogConfirmV2 from '../../components/dialog/DialogConfirmV2'
import UploadFile from '../../components/upload/UploadFile'
import SidePanel from '../../components/common/SidePanel'
import NewActivity from '../../pages/organization/NewActivity'

import iconPlus from '../../assets/images/icon-plus.svg'
import { FaBullseye } from 'react-icons/fa'
import { GrStatusGoodSmall } from 'react-icons/gr'
import warningIcon from '../../assets/images/warning-icon.png'
import successIcon from '../../assets/images/success-icon.png'
import failIcon from '../../assets/images/fail-icon.png'
import { PiWarningCircleBold, PiFloppyDiskBold } from 'react-icons/pi'
import { FaPlus } from "react-icons/fa6";

const Div = styled.div`
  .box_header{
    display: flex;
    justify-content: space-between;
  }
  .content-title {
    font-size: 36px;
    font-weight: 600;
    color: var(--Base-Font-Title-Color);
  }
  .content-title-text {
    color: var(--Base-Font-Title-SUB-Color);
    font-size: 18px;
  }
  .css_button_add{
    display: inline-block;
    align-self: flex-end;
  }
  .border_header {
    border-bottom: 1px solid rgb(234, 236, 240);
  }
  .status-badge {
    width: fit-content;
    padding: 2px 6px;
    text-align: center;
    font-weight: 500;
    border-radius: 16px;
    color: #475467;
    background: #f5f5f4;
    border: 1px #e7e5e4 solid;

    .status {
      font-size: 0.6rem;
      margin-right: 0.2rem;
      color: #475467;
    }
  }

  .table-wrapper {
    margin-top: 24px;
    border: 1px solid #eaecf0;
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
    background-color: #ffffff;

    .table-header {
      display: flex;
      justify-content: space-between;
      padding: 16px 14px;
      border-radius: 8px 8px 0 0;
      background: var(--Table-Bg-Header);

      .group {
        display: flex;
        align-items: center;
      }

      .table-title {
        font-size: 18px;
        font-weight: 600;
        color: var(--Gray-900);
      }

      .table-total {
        margin-left: 16px;
        font-size: 12px;
        font-weight: 500;
        padding: 2px 8px;
        color: var(--BADGE-Table-Font);
        background: var(--BADGE-Table-Bg);
        border-radius: 16px;
        border: 1px var(--BADGE-Table-Border) solid;
      }
    }

    .create-bt {
      text-decoration: none;
    }
  }
  .css_magin_top_10 {
    margin-top: 10px;
  }
  .css_magin_top_16 {
    margin-top: 16px;
  }
  .group_content{
    display: grid;
    grid-template-columns: repeat(2, 30% [ col-start ] 68% [ col-end ]);
    gap: 2%;
  }
  .group_button {
    display: flex;
    align-items: center;
    gap: 10px;
}
  .table-sub{
    padding: 0px 14px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}
.css_button_green{
  color: #FFFFFF;
  background: #079455;
  border-color: #079455;
}
`
const DivBoxPoint = styled.div`
display: flex;
gap: 10px;
.box_point{
  /*width: 200px;*/
  width: fit-content;
  padding: 12px 18px;
  border-radius: 12px;
  border: 1px solid #EAECF0;
  background: #FFFFF;
}
.header_text_box{
  color: #475467;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.css_magin_top_8{
  margin-top: 8px;
}
.text_point{
  color: #101828;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}
`
const DivContentLeft = styled.div`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color : #475467;
    white-space: pre-wrap;
    line-break: anywhere;   
    .main_box{
      padding : 24px;
      background: #F9FAFB;
      border-radius: 12px;
    }
    .main_topic_text{
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      color : #475467;
    }
    .css_magin_top_16 {
      margin-top: 16px;
    }
    .border_header {
      border-bottom: 1px solid rgb(234, 236, 240);
    } 

    /* ST-TimeLine 03/03/2024 */
    .timeline_workflow_text{
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      color : #344054;
      &.active{
        color : #A91F23;
      }
    }
    .timeline_workflow_text_sub{
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      color : #475467;
      &.active{
        color : #A91F23;
      }
    }
    ul.timeline_workflow{
      list-style-type: none;
      position: relative;
    }
    ul.timeline_workflow:before {
      content: ' ';
      background: #d4d9df;
      display: inline-block;
      position: absolute;
      left: 29px;
      width: 2px;
      height: 100%;
      z-index: 400;
    }
    ul.timeline_workflow > li {
      margin: 20px 0;
      padding-left: 10px;
    }
    ul.timeline_workflow > li:before {
        cursor: pointer;
        content: '';
        display: inline-block;
        position: absolute;
        border-radius: 50%;
        left: 18px;
        z-index: 400;
        text-align: center;        
        width: 24px;
        height: 24px;
        background-color: #EAECF0;
        box-shadow: 0px 0px 5px 1px #FFFFFF,inset 0 0 0 1px #EAECF0, inset 0 0 0 7px #FFFFFF, inset 0 0 0 8px #EAECF0;

    }
    ul.timeline_workflow > li.workflow_active:before {
        cursor: pointer;
        content: '';
        display: inline-block;
        position: absolute;
        border-radius: 50%;
        left: 18px;
        z-index: 400;
        text-align: center;
        width: 24px;
        height: 24px;
        background-color: #A91F23;
        box-shadow: 0px 0px 5px 4px #F4EBFF,inset 0 0 0 1px #A91F23, inset 0 0 0 7px #FEF3F2, inset 0 0 0 8px #A81E23;
    }
    ul.timeline_workflow > li.workflow_success:before {
      cursor: pointer;
      content: "✓";
      display: inline-block;
      position: absolute;
      border-radius: 50%;
      left: 18px;
      z-index: 400;
      text-align: center;
      width: 24px;
      height: 24px;
      color: #A91F23;

      background-color: #fef3f2;
      box-shadow: 0px 0px 5px 4px #F4EBFF,inset 0 0 0 1px #A91F23, inset 0 0 0 7px #FEF3F2;   
  }

    /* ED-TimeLine 03/03/2024 */    
`

const DivContentRight = styled.div`
    /*padding : 24px;*/
    background: #FFFFFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color : #475467;
    white-space: pre-wrap;
    line-break: anywhere;
    .main_box{
      padding : 24px;
    }
    .main_topic_text{
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      color : #475467;
    }
    .css_magin_top_16 {
      margin-top: 16px;
    }
    .css_magin_top_34{
      margin-top: 34px;
    }
    .border_header {
      border-bottom: 1px solid rgb(234, 236, 240);
    }
    .status-badge {
      width: fit-content;
      padding: 2px 6px;
      text-align: center;
      font-weight: 500;
      border-radius: 16px;
      color: #475467;
      background: #f5f5f4;
      border: 1px #e7e5e4 solid;
  
      .status {
        font-size: 0.6rem;
        margin-right: 0.2rem;
        color: #475467;
      }
    }
    .box_status{
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      color : #000;
      display: flex;
      gap: 16px;
      align-items: center;
    }

`

const DivInfo = styled.div`
  color: var(--Gray-600);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  .main_box_info {
    display: grid;
    grid-template-columns: repeat(2, 25% [col-start] 75% [col-end]);
    /*gap: 20px;*/
    padding-top: 10px;
    .main_box_text_head {
      font-size: 14px;
      font-weight: 600;
      color: var(--Gray-700);
    }
    .main_box_text_sub {
      font-size: 14px;
      color: var(--Gray-600);
    }
    .main_box_edit {
      border: 1px solid rgb(208, 213, 221);
      border-radius: 8px;
    }
    .main_box_fill_data {
      margin: 16px 24px;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
      gap: 20px;
      .topic_fill {
        font-size: 14px;
        color: #212121;
        margin-bottom: 5px;
      }
      .topic_detail {
        font-size: 14px;
        color: #757575;
      }
    }
    .main_box_button {
      margin: 16px 24px;
      text-align: end;
    }
    .border_buttom {
      border-bottom: 1px solid rgb(234, 236, 240);
    }
  }
  .other_box_sub{
    display: grid;
    grid-template-columns: repeat(2, 25% [col-start] 73% [col-end]);
    gap: 2%;
    align-items: start;
    /*margin-top: 12px;*/
    .other_box_sub_text_head {
      font-size: 14px;
      font-weight: 600;
      color: var(--Gray-700);
    }
    .other_box_sub_text_sub {
      font-size: 14px;
      color: var(--Gray-600);
    }
  }
  .css_magin_top_12{
    margin-top: 12px;
  }
  .TextField_employee {
    width: 100%;
  }
  .button_Edit {
    color: #6941c6;
    background-color: #f9f5ff;
    border: 1px solid #eaecf0;
  }
  .button_Cancel {
    color: #000000;
    background-color: #ffffff;
    border: 1px solid #eaecf0;
    margin-right: 1rem;
  }
  .border_header {
    border-bottom: 1px solid rgb(234, 236, 240);
    margin-bottom: 21px;
    margin-top: 21px;
  }
  .prefix_icon {
    display: flex;
    align-items: center;
    margin-left: 5px;
  }
  .input-new-2 {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr repeat(1, auto);
    grid-template-rows: auto;
    align-content: center;
  }

  .ql-container {
    min-height: 250px;
    font-family: inherit;
  }
  .md-right-1rem{
    margin-right: 1rem;
  }
  .box_org_status {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    .group_filter {
      display: flex;
      /*align-items: center;*/
    }
  }
  .css_filter_name {
    width: 150px;
    margin-right: 10px;
  }
  .css_button_filter{
    display: inline-block;
    align-self: flex-end;
  }
`

const DivWarning = styled.div`
    color: #475467;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    border-radius: 0.75rem;
    border: 1px solid #475467;
    background: #FFFFFF;
    padding: 1rem;
    .d-flex {
        display: flex;
        align-items: flex-start;
    }
    .icon-warning-container {
    }

    .warning-message-container {
      .headline-warning {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        color : #344054;
      }
      .date_application{
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        color : #667085;
        margin: 5px 0px;
      }
      .comment_application{
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        color : #667085;
        margin: 5px 0px;
      }
      .detail_click{
        color : #A91F23;
        cursor: pointer;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        margin-top : 12px;
      }
    }
    .box_icon {
        border-radius: 50%;
        background-color: #FEF0C7;
        width: 48px;
        height: 48px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-right: 12px;
        color: #A91F23;
        cursor: pointer;
      }
`
const DivAttachDoc = styled.div`
  padding: 16px 14px;
`


const ApplicationProfile = () => {
  const dispatch = useDispatch()
  const user = getAdminUser()
  const { ApplicationsId } = useParams()
  const navigate = useNavigate()

  const [DataInfo, setDataInfo] = useState(null)
  const [isOpenData, setisOpenData] = useState(false)
  const [OrganizationId, setOrganizationId] = useState(null)
  const [TimelineActive, setTimelineActive] = useState(1)

  useEffect(() => {
    const sent_data_api = {
      applications_id: ApplicationsId
    }
    dispatch(ApiApplicationsProfile(sent_data_api)).then(({ type,data_info }) => {
      if(type.endsWith('_SUCCESS')){
        if(data_info.data_info){
          const ThisDataInfo = data_info.data_info;
          setTimelineActive(ThisDataInfo.step_active);
          setDataInfo(ThisDataInfo);
          setOrganizationId(ThisDataInfo.organization_id);
          setisOpenData(true)
        }
        
      }
    })
  }, [dispatch])

  //--- ST Table ตารางกิจกรรม
  const limit_data_events = 10;
  const [total_events, settotal_events] = useState(0)
  const [page_events, setPage_events] = useState(1)
  const [totalPages_events, setTotalPages_events] = useState(0)
  const [TableCellData_events, setTableCellData_events] = useState([])
  const TableColumnData_events = [
    {
        Header: 'ลำดับ',
        accessor: 'no',
        disableSortBy: false,
    },
    {
        Header: 'วันที่จัดกิจกรรม',
        accessor: 'start_at',
        disableSortBy: false,
    },
    {
        Header: 'ชื่อกิจกรรม',
        accessor: 'name',
        disableSortBy: false,
    },
    {
        Header: 'จำนวนชั่วโมง',
        accessor: 'duration_in_hours',
        disableSortBy: false,
    },
    {
        Header: 'จำนวนผู้เข้าร่วม',
        accessor: 'participants_count',
        disableSortBy: false,
    },
  ]

  const fetchData_events = useCallback(
    async (filter) => {

      dispatch(ApiGetOrgActivity(filter)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
          if (data_info.total > 0) {
            const totalPage = Math.ceil(data_info.total / limit_data_events)
            settotal_events(data_info.total)
            setTotalPages_events(totalPage)
            const ThisSkip = data_info.skip == 0 ? 1 : (data_info.skip / limit_data_events) + 1;

            const newTableCellData = data_info.data.map((v, index) => ({
              no: limit_data_events * ThisSkip - (9 - index),
              start_at: `${DateDisplayDDMMBBB(v.activity_date)}`,
              name: v.activity_name ? DisplayTableActivityName(v.activity_name) : '-',
              description: v.activity_detail ? DisplayTableActivityDescription(v.activity_detail) : '-',
              duration_in_hours: v.activity_hours ? v.activity_hours : '-',
              participants_count: v.activity_attendee_count ? v.activity_attendee_count : '-',
            }))
            setTableCellData_events(newTableCellData)
          }else{
            settotal_events(0);
            setPage_events(0);
            setTotalPages_events(0);
            setTableCellData_events([]);
          }
         
        }
      })

    },
    [dispatch]
  )

  useEffect(() => {
    if(ApplicationsId){
        const filter = {
            skip: 0,
            limit: limit_data_events,
            application_id : ApplicationsId,
        }
        fetchData_events(filter)
    }


  }, [fetchData_events, limit_data_events,ApplicationsId])


  const onChangePaginationAttendeeAttend_events = async (value) => {
    const filter = {
      skip: (value.page  - 1) * limit_data_events, 
      limit: limit_data_events,
      application_id : ApplicationsId,
    }
    fetchData_events(filter)
    setPage_events(value.page)
  }

  const onRefreshActivity = async () => {
    const filter = {
        skip: 0,
        limit: limit_data_events,
        application_id : ApplicationsId,
    }
    fetchData_events(filter)
  }
  //--- ED Table ตารางกิจกรรม

  //--- ST Table รายการเอกสารแนบ
  const limit_data_document = 10;
  const [total_document, settotal_document] = useState(0)
  const [page_document, setPage_document] = useState(1)
  const [totalPages_document, setTotalPages_document] = useState(0)
  const [TableCellData_document, setTableCellData_document] = useState([])
  const TableColumnData_document = [
    {
        Header: 'ลำดับ',
        accessor: 'no',
        disableSortBy: false,
    },
    {
        Header: 'วันที่ทำรายการ',
        accessor: 'createdAt',
        disableSortBy: false,
    },
    {
        Header: 'รายการเอกสาร',
        accessor: 'doc_type_name',
        disableSortBy: false,
    },
    {
        Header: 'อัปโหลดโดย',
        accessor: 'upload_by',
        disableSortBy: false,
    },
    {
        Header: 'ดูไฟล์',
        accessor: 'view_file',
        disableSortBy: false,
    }
  ]

  const fetchData_document = useCallback(
    async (filter) => {
      if(DataInfo?.organization_id){
        dispatch(ApiGetApplicationDocument(filter)).then(({ type,data_info }) => {
          if(type.endsWith('_SUCCESS')){
            if (data_info.total > 0) {
              const totalPage = Math.ceil(data_info.total / limit_data_document)
              settotal_document(data_info.total)
              setTotalPages_document(totalPage)
              const ThisSkip = data_info.skip == 0 ? 1 : (data_info.skip / limit_data_document) + 1;
  
              const newTableCellData = data_info.data.map((v, index) => ({
                no: limit_data_document * ThisSkip - (9 - index),
                createdAt: `${DateDisplayDDMMBBB(v.createdAt)}`,
                doc_type_name: v.doc_type_name ? v.doc_type_name : '-',
                upload_by: v.create_by_fullname ? v.create_by_fullname : '-',
                view_file : (
                  <ButtonOutline onClick={() => ViewFile(v)}>
                      ดูไฟล์
                  </ButtonOutline>
                )
              }))
              setTableCellData_document(newTableCellData)
            }else{
              settotal_document(0);
              setPage_document(0);
              setTotalPages_document(0);
              setTableCellData_document([]);
            }
           
          }
        })
      }


    },
    [dispatch,DataInfo]
  )

  useEffect(() => {
      const filter = {
          skip: 0,
          limit: limit_data_document,
          application_id : ApplicationsId,
      }
      fetchData_document(filter)
  }, [fetchData_document, limit_data_document])


  const onChangePaginationAttendeeAttend_document = async (value) => {
    const filter = {
      skip: (value.page  - 1) * limit_data_document, 
      limit: limit_data_document,
      application_id : ApplicationsId,
    }
    fetchData_document(filter)
    setPage_document(value.page)
  }
  //--- ED Table รายการเอกสารแนบ

  const ViewFile = (Obj) => {
    window.open(Obj.file_url, "_blank");
  }
  const [ModalNewActivity, setModalNewActivity] = useState(false)

  const AddNewActivity = () => {
    setModalNewActivity(true);
    //navigate('/Activity/OrgActivity')
  }

  const gotoHome = () => {
    navigate('/', { replace: true })
  }

  const initMessageModal = {
    title: '',
    subTitle: '',
    nameItem: '',
  }
  const [ModalConfirmDialogDel, setModalConfirmDialog] = useState(false)
  const [ConfirmMessageModal, setConfirmMessageModal] = useState(initMessageModal)

  const [successModal, setSuccessModal] = useState(false)
  const [failModal, setFailModal] = useState(false)
  const [successMessageModal, setSuccessMessageModal] = useState(initMessageModal)
  const [failMessageModal, setFailMessageModal] = useState(initMessageModal)

  const onConFirm = () => {
    setConfirmMessageModal({
      title: 'ยืนยันดำเนินการตามหมายเหตุ',
      subTitle: '',
      nameItem: '',
    })
    setModalConfirmDialog(true);
  }

  const onApiUpdate = () => {
    setModalConfirmDialog(false)
    let request = {
      type_update : 'ByOrg',
      status : 100,
      FileInfoList : FileInfoList
    }
    if(request?.type_update){
      dispatch(ApiUpdateApplications(ApplicationsId,request)).then(({ type,data_info }) => {
      
        if(type.endsWith('_SUCCESS')){
          setSuccessMessageModal({
            title: 'ทำรายการสำเร็จ',
            subTitle: '',
          })
          setSuccessModal(true)
        }else{
          setFailMessageModal({
            title: 'internal server error',
            subTitle: '',
          })
          setFailModal(true)
        }
        
      })  
    }

    
  }

    //--- ST UPLOAD FILE
    const [FileInfoList, setFileInfoList] = useState([])
    const limitFileSize = 100; //--- 100MB
    const multiple = true;
    const error = false;
    const disabled = false;
    const allowedTypes = [];
    const acceptType = allowedTypes.reduce((pre, cur) => {
      return {
        ...pre,
        ...acceptList[cur],
      }
    }, {})
    const handleAddFile = async (ObjData) => {
      console.log(ObjData);
    }
    const handleDeleteFile = (key_file_id) => {
      setFileInfoList(FileInfoList.filter((v) => v.key_file_id !== key_file_id));
    }

  return (
    <Div>
        {isOpenData == true && (
          <div>       
            <div className="box_header">
                <div>
                    <div className="content-title">
                        {DataInfo?.category_type}
                    </div>
                    <div className="content-title-text">
                        {user?.organizations_name ? user?.organizations_name : '-'}
                    </div>
                </div>
            </div>
            <div className="css_magin_top_16"></div>
            <div className="border_header"></div>
            <div className="css_magin_top_16"></div>
            <div>
              <DivBoxPoint>
                  <div className="box_point">               
                    <div className="header_text_box">
                      เลขที่รับเรื่อง
                    </div>
                    <div className="css_magin_top_8"></div>
                    <div className="text_point">
                      {DataInfo?.request_no}
                    </div>
                  </div>
                  <div className="box_point">
                    <div className="header_text_box">
                      รายการ
                    </div>
                    <div className="css_magin_top_8"></div>
                    <div className="text_point">
                      {DataInfo?.category_type}
                    </div>
                  </div>
                  <div className="box_point">
                    <div className="header_text_box">
                      วันที่ทำรายการ
                    </div>
                    <div className="css_magin_top_8"></div>
                    <div className="text_point">
                      {DateDisplayDDMMBBB(DataInfo?.submitted_at)}
                    </div>
                  </div>
                  <div className="box_point">
                    <div className="header_text_box">
                      สถานะ
                    </div>
                    <div className="css_magin_top_8"></div>
                    <div className="text_point">
                        <div
                          className="status-badge"
                          style={{
                            color: DataInfo?.status_badge_color?.color,
                            borderColor: DataInfo?.status_badge_color?.border,
                            backgroundColor: DataInfo?.status_badge_color?.background,
                          }}
                        >
                          {DataInfo?.status_name}
                        </div>                      
                    </div>
                  </div>
              </DivBoxPoint>
            </div>
            <div className="css_magin_top_16"></div>
            <div className="group_content">
              <DivContentLeft>
                <div className="main_box">
                  <div className="main_topic_text">
                    ขั้นตอนการดำเนินการ
                  </div>
                  <div className="css_magin_top_16"></div>
                  <div className="border_header"></div>
                  <div className="css_magin_top_16"></div>
                  <div>
                    <ul className="timeline_workflow">
                      <li className={`${DataInfo?.step_active > 1 ? 'workflow_success' : DataInfo?.step_active == 1 ? 'workflow_active' : ''}`} onClick={() => setTimelineActive(1)}>
                          <div className={`timeline_workflow_text ${DataInfo?.step_active > 1 || DataInfo?.step_active == 1 ? 'active' : ''}`}>
                            1. ตรวจสอบเอกสาร
                          </div>
                          <div className={`timeline_workflow_text_sub ${DataInfo?.step_active > 1 || DataInfo?.step_active == 1 ? 'active' : ''}`}>
                            ตรวจสอบข้อมูลและเอกสาร
                          </div>
                      </li>
                      <li className={`${DataInfo?.step_active > 2 ? 'workflow_success' : DataInfo?.step_active == 2 ? 'workflow_active' : ''}`} onClick={() => setTimelineActive(2)}>
                          <div className={`timeline_workflow_text ${DataInfo?.step_active > 2 || DataInfo?.step_active == 2 ? 'active' : ''}`}>
                            2. ตรวจประเมินออนไลน์
                          </div>
                          <div className={`timeline_workflow_text_sub ${DataInfo?.step_active > 2 || DataInfo?.step_active == 2 ? 'active' : ''}`}>
                            นัดประเมินผ่านออนไลน์
                          </div>
                      </li>
                      <li className={`${DataInfo?.step_active > 3 ? 'workflow_success' : DataInfo?.step_active == 3 ? 'workflow_active' : ''}`} onClick={() => setTimelineActive(3)}>
                          <div className={`timeline_workflow_text ${DataInfo?.step_active > 3 || DataInfo?.step_active == 3 ? 'active' : ''}`}>
                            3. คณะกรรมการพิจารณาอนุมัติ 
                          </div>
                          <div className={`timeline_workflow_text_sub ${DataInfo?.step_active > 3 || DataInfo?.step_active == 3 ? 'active' : ''}`}>
                            ผลการอนุมัติจากคณะกรรมการสภาาวิศวกร
                          </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </DivContentLeft>
              <DivContentRight>
              {TimelineActive == 1 && (
                <div className="main_box">
                  <div className="main_topic_text">
                    1. ตรวจสอบเอกสาร
                  </div>
                  <div className="css_magin_top_16"></div>
                  <div className="border_header"></div>
                  <div className="css_magin_top_16"></div>
                  <div className="box_status">
                    <div>
                      สถานะ :
                    </div>
                    <div
                          className="status-badge"
                          style={{
                            color: DataInfo?.result_document_badge_color?.color,
                            borderColor: DataInfo?.result_document_badge_color?.border,
                            backgroundColor: DataInfo?.result_document_badge_color?.background,
                          }}
                        >
                          {DataInfo?.result_document}
                        </div> 
                  </div>
                  {DataInfo?.applications_status == 101 && (
                    <div>
                      <div className="css_magin_top_34"></div>
                      <div>
                      <DivWarning>
                          <div className="d-flex">
                              <div className="icon-warning-container">
                                  <div className="box_icon" >
                                      <PiWarningCircleBold size={25} />
                                  </div>                    
                              </div>
                              <div className="warning-message-container">
                                  <div className="headline-warning">
                                    หมายเหตุจากเจ้าหน้าที่
                                  </div>
                                  <div className="date_application">
                                    วันที่ครบกำหนดแก้ไขข้อมูล : {DataInfo?.due_date_document ? DateDisplayDDMMBBB(DataInfo?.due_date_document) : '-'}
                                  </div>
                                  <div className="comment_application">
                                    {DataInfo?.comment_document ? DataInfo?.comment_document : '-'}
                                  </div>
                                  <div>
                                    <ButtonSecondary   onClick={() => onConFirm()}>
                                      แจ้งส่งตรวจ
                                    </ButtonSecondary>
                                  </div>
                              </div>
                          </div>                                    
                      </DivWarning> 
                      </div>
                    </div>
                  )}
                  <div className="css_magin_top_34"></div>
                  <DivInfo>
                    <div className="main_box_info">
                        <div>
                            <div className="main_box_text_head">ข้อมูลเกี่ยวกับองค์กรแม่ข่าย</div>
                            <div className="main_box_text_sub">ท่านสามารถแก้ไขข้อมูลที่หน้าข้อมูลองค์กร</div>
                        </div>
                        <div>
                          <div className="other_box_sub">
                            <div className="other_box_sub_text_head">ชื่อองค์กรแม่ข่าย</div>
                            <div className="other_box_sub_text_sub">{DataInfo?.name_th}</div>
                          </div>
                          <div className="css_magin_top_12"></div>
                          <div className="other_box_sub">
                            <div className="other_box_sub_text_head">ชื่อย่อองค์กรแม่ข่าย</div>
                            <div className="other_box_sub_text_sub">{DataInfo?.name_abbr}</div>
                          </div>
                          <div className="css_magin_top_12"></div>
                          <div className="other_box_sub">
                            <div className="other_box_sub_text_head">เลขประจำตัวผู้เสียภาษี 13 หลัก</div>
                            <div className="other_box_sub_text_sub">{DataInfo?.tin}</div>
                          </div>
                          <div className="css_magin_top_12"></div>
                          <div className="other_box_sub">
                            <div className="other_box_sub_text_head">ประเภทองค์กร</div>
                            <div className="other_box_sub_text_sub">{DataInfo?.org_type_name}</div>
                          </div>
                          <div className="css_magin_top_12"></div>
                          <div className="other_box_sub">
                            <div className="other_box_sub_text_head">เบอร์โทรศัพท์องค์กร</div>
                            <div className="other_box_sub_text_sub">{DataInfo?.org_phone_no}</div>
                          </div>
                          <div className="css_magin_top_12"></div>
                          <div className="other_box_sub">
                            <div className="other_box_sub_text_head">เว็ปไซด์หน่วยงาน</div>
                            <div className="other_box_sub_text_sub">{DataInfo?.website}</div>
                          </div>
                          <div className="css_magin_top_12"></div>
                          <div className="other_box_sub">
                            <div className="other_box_sub_text_head">ที่อยู่องค์กร</div>
                            <div className="other_box_sub_text_sub">{DataInfo?.full_address}</div>
                          </div>
                        </div>
                    </div>
                  </DivInfo>       
                  <div className="css_magin_top_34"></div>
                  <div className="table-wrapper">
                      <div className="table-header">
                          <div className="group">
                              <div className="table-title">ตารางกิจกรรม</div>
                              <div className="table-total">
                                  {total_events} รายการ
                              </div>
                          </div>
                          <div className="css_button_filter">
                              <div className="group_button">
                                  <ButtonSecondary  append={<FaPlus />} onClick={AddNewActivity}>
                                    เพิ่มรายการ
                                  </ButtonSecondary>
                              </div>
                          </div>
                      </div>
                      <div className="table-sub">ผลงานย้อนหลัง 3 ปี และแผนการอบรมสัมนาประจำปีที่ยื่น</div>
                      <div className="css_magin_top_10">
                          <TableV2
                          columns={TableColumnData_events}
                          data={TableCellData_events}
                          onStateChange={onChangePaginationAttendeeAttend_events}
                          pageCount={totalPages_events}
                          page={page_events}
                          loading={false}
                          />
                      </div>
                  </div>
                  <div className="css_magin_top_34"></div>
                  <div className="table-wrapper">
                      <div className="table-header">
                          <div className="group">
                              <div className="table-title">รายการเอกสารแนบ</div>
                              <div className="table-total">
                                  {total_document} รายการ
                              </div>
                          </div>
                          {
                            /*
                            <div className="css_button_filter">
                                <div className="group_button">
                                    <ButtonSecondary  append={<FaPlus />} className="css_button_green">
                                      แนบเอกสารเพิ่มเติม
                                    </ButtonSecondary>
                                </div>
                            </div>
                            */
                          }


                      </div>
                      <div className="css_magin_top_10">
                          <TableV2
                          columns={TableColumnData_document}
                          data={TableCellData_document}
                          onStateChange={onChangePaginationAttendeeAttend_document}
                          pageCount={totalPages_document}
                          page={page_document}
                          loading={false}
                          />
                      </div>
                      {DataInfo?.applications_status == 101 && (
                      <div>
                        <DivAttachDoc>
                          <UploadFile
                              multiple={multiple}
                              accept={acceptType}
                              file={FileInfoList.map((v) => ({ ...v, url: `${v.url}` }))}
                              onAddFile={handleAddFile}
                              onDeleteFile={handleDeleteFile}
                              error={error}
                              disabled={disabled}
                              FileListData={FileInfoList}
                              setFileListData={setFileInfoList}
                              isOneFile={true}
                              limitFileSize={limitFileSize}
                          />
                        </DivAttachDoc>
                      </div>
                      )}
                  </div>                   
                </div>
              )}
              {TimelineActive == 2 && (
                <div className="main_box">
                  <div className="main_topic_text">
                    2. ตรวจประเมินออนไลน์
                  </div>
                  <div className="css_magin_top_16"></div>
                  <div className="border_header"></div>
                  <div className="css_magin_top_16"></div>
                  <div className="box_status">
                    <div>
                      สถานะ :
                    </div>
                    <div
                          className="status-badge"
                          style={{
                            color: DataInfo?.result_assessment_badge_color?.color,
                            borderColor: DataInfo?.result_assessment_badge_color?.border,
                            backgroundColor: DataInfo?.result_assessment_badge_color?.background,
                          }}
                        >
                          {DataInfo?.result_assessment}
                        </div> 
                  </div>
                  <div className="css_magin_top_34"></div>
                  <DivInfo>
                    <div className="main_box_info">
                        <div>
                            <div className="main_box_text_head">นัดตรวจประเมิน</div>
                            <div className="main_box_text_sub">ลงรายละเอียดนัดตรวจประเมิน</div>
                        </div>
                        <div>
                          <div className="other_box_sub">
                            <div className="other_box_sub_text_head">วันที่นัดตรวจประเมิน</div>
                            <div className="other_box_sub_text_sub">{DataInfo?.date_assessment ? DateDisplay(DataInfo?.date_assessment) : '-'}</div>
                          </div>
                          <div className="css_magin_top_12"></div>
                          <div className="other_box_sub">
                            <div className="other_box_sub_text_head">เวลา</div>
                            <div className="other_box_sub_text_sub">{DataInfo?.assessment_time_display ? DataInfo?.assessment_time_display : '-'}</div>
                          </div>
                          <div className="css_magin_top_12"></div>
                          <div className="other_box_sub">
                            <div className="other_box_sub_text_head">Link เข้าประชุม</div>
                            <div className="other_box_sub_text_sub">{DataInfo?.assessment_link ? DataInfo?.assessment_link : '-'}</div>
                          </div>
                          <div className="css_magin_top_12"></div>
                          <div className="other_box_sub">
                            <div className="other_box_sub_text_head">Username / ID</div>
                            <div className="other_box_sub_text_sub">{DataInfo?.zoom_id ? DataInfo?.zoom_id : '-'}</div>
                          </div>
                          <div className="css_magin_top_12"></div>
                          <div className="other_box_sub">
                            <div className="other_box_sub_text_head">Password</div>
                            <div className="other_box_sub_text_sub">{DataInfo?.zoom_password ? DataInfo?.zoom_password : '-'}</div>
                          </div>
                          <div className="css_magin_top_12"></div>
                          <div className="other_box_sub">
                            <div className="other_box_sub_text_head">หมายเหตุ</div>
                            <div className="other_box_sub_text_sub">{DataInfo?.comment_assessment ? DataInfo?.comment_assessment : '-'}</div>
                          </div>
                        </div>

                    </div>
                  </DivInfo>
                </div>
              )}
              {TimelineActive >= 3 && (
                <div className="main_box">
                  <div className="main_topic_text">
                    3. คณะกรรมการพิจารณาอนุมัติ
                  </div>
                  <div className="css_magin_top_16"></div>
                  <div className="border_header"></div>
                  <div className="css_magin_top_16"></div>
                  <div className="box_status">
                    <div>
                      สถานะ :
                    </div>
                    <div
                          className="status-badge"
                          style={{
                            color: DataInfo?.result_consideration_badge_color?.color,
                            borderColor: DataInfo?.result_consideration_badge_color?.border,
                            backgroundColor: DataInfo?.result_consideration_badge_color?.background,
                          }}
                        >
                          {DataInfo?.result_consideration}
                        </div> 
                  </div>
                  <div className="css_magin_top_34"></div>
                  <DivInfo>
                    <div className="main_box_info">
                        <div>
                            <div className="main_box_text_head">รอพิจารณาผลจากคณะกรรมการ</div>
                            <div className="main_box_text_sub">กรุณารอระบุวันที่ประกาศผล</div>
                        </div>
                        <div>
                          <div className="other_box_sub">
                            <div className="other_box_sub_text_head">วันที่ประกาศผล</div>
                            <div className="other_box_sub_text_sub">{DataInfo?.approved_at ? DateDisplayDDMMBBB(DataInfo?.approved_at) : '-'}</div>
                          </div>
                          <div className="css_magin_top_12"></div>
                          <div className="other_box_sub">
                            <div className="other_box_sub_text_head">ผลการอนุมัติ</div>
                            <div className="other_box_sub_text_sub">{DataInfo?.approved_at ? DataInfo?.result_consideration : '-'}</div>
                          </div>
                          <div className="css_magin_top_12"></div>
                          <div className="other_box_sub">
                            <div className="other_box_sub_text_head">หมายเหตุ</div>
                            <div className="other_box_sub_text_sub">{DataInfo?.comment_consideration ? DataInfo?.comment_consideration : '-'}</div>
                          </div>
                        </div>

                    </div>
                  </DivInfo>
                </div>
              )}

              </DivContentRight>
            </div>
          </div>
        )}
      <SidePanel isOpen={ModalNewActivity} setIsOpen={setModalNewActivity} width={400}>
            <NewActivity
              width={400} 
              onClose={() => setModalNewActivity(false)} 
              onSubmit={() => {
                setModalNewActivity(false)
                onRefreshActivity()
              }}
              OrganizationId={OrganizationId}
              ApplicationsId={ApplicationsId}
            />
      </SidePanel>

      <DialogConfirmV2
        open={Boolean(ModalConfirmDialogDel)}
        onClose={() => setModalConfirmDialog(false)}
        onNo={() => setModalConfirmDialog(false)}
        onSubmit={() => {
          setModalConfirmDialog(false)
          onApiUpdate()
        }}
        icon={warningIcon}
        title={ConfirmMessageModal.title}
        subTitle={ConfirmMessageModal.subTitle}
        nameItem={ConfirmMessageModal.nameItem}
        textYes='ยืนยัน'
        textNo='ยกเลิก'
      />

      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => setSuccessModal(false)}
        onSubmit={() => {
          setSuccessModal(false)
          gotoHome()
        }}
        icon={successIcon}
        title={successMessageModal.title}
        description={successMessageModal.subTitle}
        textYes='ตกลง'
      />
      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={() => {
          setFailModal(false)
        }}
        icon={failIcon}
        title={failMessageModal.title}
        description={failMessageModal.subTitle}
        textYes='ตกลง'
      />
    </Div>
  )
}

export default ApplicationProfile
