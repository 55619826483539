import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import {  getAdminUser } from '../../utils/common'
import { getOrgUserAccount } from '../../redux/actions/organizations'
import { Api_org_num_status_elearning,Api_org_vw_elearning,ApiUpdateOrgElearning,Api_Post_OrgElearning } from '../../redux/actions/elearning'


import Button from '../../components/form/button/Button'
import ButtonSecondary from '../../components/form/button/ButtonSecondary'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import TableV2 from '../../components/common/TableV2'
import SearchText from '../../components/form/SearchText'
import Dropdown from '../../components/form/Dropdown'
import Date from '../../components/form/Date'
import Field from '../../components/form/Field'
import SidePanel from '../../components/common/SidePanel'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import DialogFail from '../../components/dialog/DialogFail'
import DialogConfirmV2 from '../../components/dialog/DialogConfirmV2'

import iconPlus from '../../assets/images/icon-plus.svg'
import iconFileDowload from '../../assets/images/icon-file-download.svg'
import iconEdit from '../../assets/images/edit-icon.png'
import iconDelete from '../../assets/images/icon-bin-red.svg'
import iconDuplicate from '../../assets/images/Duplicate.png'
import warningIcon from '../../assets/images/warning-icon.png'
import successIcon from '../../assets/images/success-icon.png'
import failIcon from '../../assets/images/fail-icon.png'

import { FaBullseye } from 'react-icons/fa'
import { GrStatusGoodSmall } from 'react-icons/gr'

const Div = styled.div`
  .box_header{
    display: flex;
    justify-content: space-between;
  }
  .content-title {
    font-size: 36px;
    font-weight: 600;
    color: var(--Base-Font-Title-Color);
  }
  .content-title-text {
    color: var(--Base-Font-Title-SUB-Color);
    font-size: 18px;
  }
  .css_button_add{
    display: inline-block;
    align-self: flex-end;
  }
  .border_header {
    border-bottom: 1px solid rgb(234, 236, 240);
    margin-bottom: 21px;
    margin-top: 21px;
  }
  .status-badge {
    /*width: 150px;*/
    /*padding: 0.2rem;*/
    width: fit-content;
    padding: 2px 6px;
    text-align: center;
    font-weight: 500;
    border-radius: 16px;
    color: #475467;
    background: #f5f5f4;
    border: 1px #e7e5e4 solid;

    &.expire {
      color:#B42318;
      background: #FEF3F2;
      border: 1px #FECDCA solid;
    }
    &.active {
      color:#067647;
      background: #ECFDF3;
      border: 1px #ABEFC6 solid;
    }

    .status {
      font-size: 0.6rem;
      margin-right: 0.2rem;
      color: #475467;
      

      &.expire {
        color:#B42318;
      }
      &.active {
        color:#067647;
      }

    }
  }

  .table-wrapper {
    border: 1px solid #eaecf0;
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
    background-color: #ffffff;

    .table-header {
      display: flex;
      justify-content: space-between;
      padding: 16px 14px;
      border-radius: 8px 8px 0 0;
      background: var(--Table-Bg-Header);

      .group {
        display: flex;
        align-items: center;
      }

      .table-title {
        font-size: 18px;
        font-weight: 600;
        color: var(--Gray-900);
      }

      .table-total {
        margin-left: 16px;
        font-size: 12px;
        font-weight: 500;
        padding: 2px 8px;
        color: var(--BADGE-Table-Font);
        background: var(--BADGE-Table-Bg);
        border-radius: 16px;
        border: 1px var(--BADGE-Table-Border) solid;
      }
    }

    .create-bt {
      text-decoration: none;
    }
  }
  .css_magin_top_10 {
    margin-top: 10px;
  }
  .css_magin_top_24 {
    margin-top: 24px;
  }
  .title_dowload{
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
  }
  .cover_img{
    img {
      width: 74px;
      height: 40px;
    }
  }
  .cursor_pointer {
    cursor: pointer;
  }
`

const DivFilter = styled.div`
min-height: 68px;
background-color: var(--Gray-50);
border-radius: 8px;
margin-bottom: 21px;
padding: 10px;
.content-fillter {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    .search-wrapper {
        margin-right: 12px;
    }

    .filter-list {
        flex-basis: 100%;
        margin-top: 24px;
    }

    .group_filter {
        display: flex;
        align-items: center;
    }
}
.css_filter_name {
    min-width: 380px;
    margin-right: 10px;
}
.mr-1-rem {
    margin-right: 1rem;
}
.css_button_filter{
    display: inline-block;
    align-self: flex-end;
}
.dd_filter{
    min-width: 380px;
}
`

const DivTab = styled.div`
width: 100%;
margin-bottom: 1rem;
margin-top: 1rem;
display: flex;
/*border-bottom: 1px solid var(--Tab-Border-Default);*/

.btn-menu-style {
    border: none;
    padding: 10px 18px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    background-color: var(--Tab-Bg-Default);
    /*border-bottom: 0px solid var(--Tab-Border-Default);*/
    border-bottom: 2px solid var(--Tab-Border-Default);
    color: var(--Tab-Font-Default);  
    font-size: 14px;
    /*font-family: 'Inter','Noto Sans Thai';*/
    font-family: inherit;
}

.menu-active {
    border: none;
    padding: 10px 18px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 2px solid var(--Tab-Border-Current) !important;
    color: var(--Tab-Font-Current); 
    background-color: var(--Tab-Bg-Current);
    /*font-family: 'Inter','Noto Sans Thai';*/
    font-family: inherit;
}
.box_sub_menu{
    display: flex;
    justify-content: space-between;
    gap: 10px;

    .box_num_notification {
      display: inline-block;
      align-self: center;
      height: 22px;
      font-size: 12px;
      font-weight: 500;
      padding: 2px 8px;
      
      border-radius: 16px;
      color: #475467;
      background: #f5f5f4;
      border: 1px #e7e5e4 solid;

      &.active {
        color:#A81E23; 
        background: #FEF3F2;
        border: 1px #FECDCA solid;
      }

    }

  }
`



const Elearning = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const user = getAdminUser()

  const [ElearningId, setElearningId] = useState(null)
  const [isOpenData, setisOpenData] = useState(false)
  const [DataInfo, setDataInfo] = useState(null)
  const [filterName, setfilterName] = useState('')
  const [filterUser, setfilterUser] = useState('')
  const [filterDate, setfilterDate] = useState(null)
  const [filterStatus, setfilterStatus] = useState('')
  const [user_option, setuser_option] = useState([])
  useEffect(() => {
    const filter = {
      skip: 0,
      limit: 1000,
      status_id : 1,
    }
    dispatch(getOrgUserAccount(filter)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
            const neworg_type_option= data_info?.data?.map((v) => ({
                text: v?.full_name,
                value: v?.user_id,
            }))
            setuser_option([...neworg_type_option])
        }
    })
  }, [dispatch])


  const initMessageModal = {
    headline: '',
    message: '',
  }
  const [ModalConfirmDialog, setModalConfirmDialog] = useState(false)
  const [ModalConfirmDialogDel, setModalConfirmDialogDel] = useState(false)
  const [ConfirmMessageModal, setConfirmMessageModal] = useState(initMessageModal)

  const [successModal, setSuccessModal] = useState(false)
  const [failModal, setFailModal] = useState(false)
  const [successMessageModal, setSuccessMessageModal] = useState(initMessageModal)
  const [failMessageModal, setFailMessageModal] = useState(initMessageModal)

  const limit_data = 10;
  const [total, settotal] = useState(0)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [TableCellData, setTableCellData] = useState([])
  const TableColumnData = [
    {
        Header: 'ลำดับ',
        accessor: 'no',
        disableSortBy: false,
    },
    {
        Header: 'ชื่อบทความ',
        accessor: 'name',
        disableSortBy: false,
    },
    {
        Header: 'รูปหน้าปก',
        accessor: 'cover_photo',
        disableSortBy: false,
    },
    {
        Header: 'สถานะ',
        accessor: 'status',
        disableSortBy: false,
    },
    {
        Header: 'มีสอบหรือไม่',
        accessor: 'is_exam',
        disableSortBy: false,
    },
    {
        Header: 'จำนวนผุ้เข้าชม',
        accessor: 'num_user_view',
        disableSortBy: false,
    },
    {
        Header: 'จำนวนคนสอบผ่าน',
        accessor: 'num_user_exam_pass',
        disableSortBy: false,
    },
    {
        Header: 'อัตราสอบผ่าน',
        accessor: 'num_user_exam_percent_pass',
        disableSortBy: false,
    },
    {
        Header: 'วันที่สร้าง',
        accessor: 'create_date_display',
        disableSortBy: false,
    },
    {
        Header: 'สร้างโดย',
        accessor: 'create_by_fullname',
        disableSortBy: false,
    },
    {
        accessor: 'duplicate',
        disableSortBy: false,
    },
    {
        accessor: 'buttonDelete',
        disableSortBy: false,
    },
    {
        accessor: 'linkEdit',
        disableSortBy: false,
    },
    {
        accessor: 'verify',
        disableSortBy: false,
    },
  ]

  useEffect(() => {
    dispatch(Api_org_num_status_elearning()).then(({ type,data_info }) => {
      if(type.endsWith('_SUCCESS')){
        if(data_info.data_info){
          setDataInfo(data_info.data_info);
          setisOpenData(true)
        }
        
      }
    })
  }, [dispatch])

  const fetchData = useCallback(
    async (filter) => {

      dispatch(Api_org_vw_elearning(filter)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
          if (data_info.total > 0) {
            const totalPage = Math.ceil(data_info.total / limit_data)
            settotal(data_info.total)
            setTotalPages(totalPage)

            const newTableCellData = data_info.data.map((v, index) => ({
              no: limit_data * page - (9 - index),
              name: v.topic_name ? v.topic_name : '-',
              cover_photo: (
                v.cover_img ? <div className="cover_img">
                  <img src={v.cover_img} />
                </div> : <div >-</div>
              ),
              status: (
                v.status_name ? 
                <div
                  className="status-badge"
                  style={{
                    color: v.status_badge_color?.color,
                    borderColor: v.status_badge_color?.border,
                    backgroundColor: v.status_badge_color?.background,
                  }}
                >
                  <GrStatusGoodSmall 
                    className="status"
                    style={{
                      color: v.status_badge_color?.color,
                    }}
                  />
                  {v.status_name}
                </div>
                : 
                <div >-</div>
              ),
              is_exam: v.is_exam_display ? v.is_exam_display : '-',
              num_user_view: v.num_user_view ? v.num_user_view : '-',
              num_user_exam_pass: v.num_user_exam_pass ? v.num_user_exam_pass : '-',
              num_user_exam_percent_pass: v.percent_exam_pass ? v.percent_exam_pass : '-',
              create_date_display: v.created_at_display ? v.created_at_display : '-',
              create_by_fullname: v.create_by_fullname ? v.create_by_fullname : '-',
              duplicate: <img className="cursor_pointer" src={iconDuplicate} title="duplicate" />,
              buttonDelete: <img className="cursor_pointer" src={iconDelete} title="Delete" onClick={() => DelData(v)} />,
              linkEdit: <img className="cursor_pointer" src={iconEdit} title="Edit"  onClick={() => EditData(v)} />,
            }))
            setTableCellData(newTableCellData)
          }else{
            settotal(0);
            setPage(0);
            setTotalPages(0);
            setTableCellData([]);
          }
         
        }
      })

    },
    [dispatch]
  )

  useEffect(() => {
    const filter = {
      skip: 0,
      limit: limit_data,
    }
    fetchData(filter) //-- ปิด รอ API
  }, [fetchData, limit_data])


  const onChangePaginationAttendeeAttend = async (value) => {
    const filter = {
      skip: (value.page  - 1) * limit_data, 
      limit: limit_data,
    }
    fetchData(filter)
    setPage(value.page)
  }

  const onSearch = () => {
    const filter = {
      skip: 0,
      limit: limit_data,
      status_id : filterStatus,
      name : filterName,
      user : filterUser,
      date : filterDate,
    }
    fetchData(filter)
    setPage(1)
  }
  const onClearSearch = () => {
    setfilterStatus('')
    setfilterName('')
    setfilterUser('')
    setfilterDate(null)
    const filter = {
      skip: 0,
      limit: limit_data,
    }
    fetchData(filter)
    
    setPage(1)
  }

  const onClearfilterName = () => {
    setfilterName('');
  }

  const OpenCreateModal = () => {
    //navigate('/Activity/NewElearning')
    const request = {
      data_content: "[]",
      status_id : 0
    }
    dispatch(Api_Post_OrgElearning(request)).then(({ type,data_info }) => {
      
      if(type.endsWith('_SUCCESS')){
        if(data_info.id){
          navigate('/Activity/ElearningInfo/' + data_info.id)
        }else{
          
            setFailMessageModal({
                headline: 'ไม่สามารถสร้างบทความได้',
                message: '',
            })
            setFailModal(true)
          
        }
      }else{
        setFailMessageModal({
          headline: 'internal server error',
          message: '',
        })
        setFailModal(true)
      }
      
    })  
  }
  const DowloadReport = () => {
    alert('DowloadReport')
  }

  const DelData = (data) => {
    setElearningId(data.id)
    setConfirmMessageModal({
        headline: 'กรุณายืนยันการลบบทความนี้',
        message: '',
    })
    setModalConfirmDialogDel(true);
  }
  const EditData = (data) => {
    navigate('/Activity/ElearningInfo/' + data.id)
  }

  const handleProcess = (type_status_id) => {
    setModalConfirmDialog(false)
    const request = {
      status_id : type_status_id,
      is_update_status : true
    }
    dispatch(ApiUpdateOrgElearning(ElearningId,request)).then(({ type,data_info }) => {
      
      if(type.endsWith('_SUCCESS')){
        if(data_info.code == 200){
          setSuccessMessageModal({
            headline: 'ลบรายการสำเร็จ',
            message: '',
          })
          setSuccessModal(true)
        }else{
          
            setFailMessageModal({
                headline: data_info.message,
                message: '',
            })
            setFailModal(true)
          
        }
      }else{
        setFailMessageModal({
          headline: 'internal server error',
          message: '',
        })
        setFailModal(true)
      }
      
    })  
  }

  const [tabactive, setTabactive] = useState('All')
  const [menuTabList, setMenuTabList] = useState([
    {
        name: 'บทความทั้งหมด',
        status_id : '',
        value: 'All',
        active_status: true,
    },
    {
        name: 'รออนุมัติ',
        status_id : '2',
        value: 'Wait',
        active_status: false,
    },
    {
        name: 'ไม่ผ่านขอให้แก้ไข',
        status_id : '4',
        value: 'RequestEdit',
        active_status: false,
    },
    {
        name: 'อนุมัติ',
        status_id : '3',
        value: 'Approve',
        active_status: false,
    },
    {
        name: 'ปฏิเสธ',
        status_id : '5',
        value: 'Cancle',
        active_status: false,
    },
    {
        name: 'ร่าง',
        status_id : '1',
        value: 'Draft',
        active_status: false,
    },
  ])
  const handleClickTaskMenuTab = (data) => {
    let tmpList = [...menuTabList]
    for (let i = 0; i < tmpList.length; i++) {
      if (tmpList[i].value == data.value) {
        tmpList[i].active_status = true
        setTabactive(tmpList[i].value)
        //---- QUERY
        setfilterStatus(tmpList[i].status_id)
        setfilterName('')
        setfilterUser('')
        setfilterDate(null)
        const filter = {
          skip: 0,
          limit: limit_data,
          status_id: tmpList[i].status_id,
        }
        fetchData(filter)
        setPage(1)

      } else {
        tmpList[i].active_status = false
      }
    }
    setMenuTabList([...tmpList])
  }
  

  return (
    <Div>
      <header>
        <div className="box_header">
            <div>
                <div className="content-title">
                    จัดการบทความ E-Learning
                </div>
                <div className="content-title-text">
                    จัดการเนื้อหาการเรียนรู้ได้อย่างง่ายดายและมีประสิทธิภาพ
                </div>
            </div>
            <div className="css_button_add">
              <Button append={<img src={iconPlus} />} onClick={OpenCreateModal} >
                สร้างบทความใหม่
              </Button>
            </div>
        </div>
        
        {/* Tab */}
        <div className="css_magin_top_24"></div>
        {isOpenData == true && (
          <DivTab>
              {menuTabList?.map((item, index) => (
                  <button
                  id={'this_name-' + item.value}
                  key={'btn-' + index}
                  className={item.active_status ? 'menu-active' : 'btn-menu-style'}
                  onClick={() => handleClickTaskMenuTab(item)}
                  >
                      <div className="box_sub_menu">
                          <div>
                              {item.name}
                          </div>
                          <div className={`box_num_notification ${item.active_status ? 'active' : ''}`}>
                              {DataInfo[item.value]}
                          </div>
                      </div>
                  
                  </button>
              ))}
          </DivTab>
        )}
        {/* Dowload */}
        <div className="css_magin_top_24"></div>
        <div className="box_header">
            <div className="title_dowload">
                รายการบทความทั้งหมด
            </div>
            <div className="css_button_add">
              <ButtonSecondary append={<img src={iconFileDowload} />} onClick={DowloadReport} >
                ดาวน์โหลดรายงาน
              </ButtonSecondary>
            </div>
        </div>
        {/* ค้นหา */}
        <div className="css_magin_top_24"></div>
        <DivFilter>
          <div className="content-fillter">
            <div className="group_filter">
              <div className="css_filter_name">
                <Field className="field" label="ค้นหาบทความ">
                  <SearchText
                    placeholder="ค้นหาบทความ"
                    value={filterName}
                    onChange={(e) => setfilterName(e.target.value)}
                    onClear={onClearfilterName}
                  />
                </Field>
              </div>
              <div className="mr-1-rem">
                <Field className="field" label="สร้างโดย">
                  <Dropdown
                      id={`dd_filterUser`}
                      className="dd_filter"
                      value={filterUser}
                      optionList={user_option}
                      onChange={(v) => setfilterUser(v)}
                      placeHolder={'เลือก'}
                  />
                </Field>
              </div>
              <div className="mr-1-rem">
                <Field className="field" label="วันที่สร้าง">
                    <Date
                        className={'input-calendar'}
                        value={filterDate}
                        onChange={(v) => setfilterDate(v)}
                    />
                </Field>
              </div>
              <div className="css_button_filter mr-1-rem">
                  <Button onClick={onSearch} >
                    ค้นหา
                  </Button>
              </div>
              <div className="css_button_filter mr-1-rem">
                <ButtonSecondary onClick={onClearSearch} >
                  ล้างการค้นหา
                </ButtonSecondary>
              </div>
            </div>
          </div>
        </DivFilter>

        {/* Table */}
        <div className="css_magin_top_24"></div>
        <div className="table-wrapper">
            <div className="table-header">
                <div className="group">
                <div className="table-title">จำนวนรายการ</div>
                <div className="table-total">
                    {total} รายการ
                </div>
                </div>
            </div>


          <div className="css_magin_top_10">
            <TableV2
              columns={TableColumnData}
              data={TableCellData}
              onStateChange={onChangePaginationAttendeeAttend}
              pageCount={totalPages}
              page={page}
              loading={false}
            />
          </div>
        </div>
      </header>

      <DialogConfirmV2
        open={Boolean(ModalConfirmDialogDel)}
        onClose={() => setModalConfirmDialogDel(false)}
        onNo={() => setModalConfirmDialogDel(false)}
        onSubmit={() => {
          setModalConfirmDialogDel(false)
          handleProcess(0)
        }}
        icon={warningIcon}
        title={ConfirmMessageModal.headline}
        nameItem={ConfirmMessageModal.message}
        textYes='ยืนยัน'
        textNo='ยกเลิก'
      />

      {/* Dialog */}
      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => setSuccessModal(false)}
        onSubmit={() => {
          setSuccessModal(false)
          onClearSearch()
        }}
        icon={successIcon}
        title={successMessageModal.headline}
        description={successMessageModal.message}
        textYes='ตกลง'
      />
      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={() => {
          setFailModal(false)
          onClearSearch()
        }}
        icon={failIcon}
        title={failMessageModal.headline}
        description={failMessageModal.message}
        textYes='ตกลง'
      />
    </Div>
  )
}

export default Elearning
