import { createGlobalStyle, css } from 'styled-components'

export const THEME = {
  light: 'light',
  dark: 'dark',
}

// A81E23 [COE]
const lightThemeA81E23 = css`
  --Base-White: #FFFFFF;
  --Primary-800: #53389e;
  --Primary-700: #6941c6;
  --Primary-600: #7f56d9;
  --Primary-500: #9e77ed;
  --Primary-300: #d6bbfb;
  --Primary-200: #e9d7fe;
  --Primary-50: #f9f5ff;
  --Primary-25: #fcfaff;
  --Gray-900: #101828;
  --Gray-700: #344054;
  --Gray-600: #475467;
  --Gray-500: #667085;
  --Gray-400: #98a2b3;
  --Gray-300: #d0d5dd;
  --Gray-200: #eaecf0;
  --Gray-100: #f2f4f7;
  --Gray-50: #f9fafb;
  --Gray-blue-700: #363f72;
  --Gray-blue-200: #d5d9eb;
  --Gray-blue-50: #f8f9fc;
  --Purple-700: #5925dc;
  --Purple-200: #d9d6fe;
  --Purple-50: #f4f3ff;
  --Gray-iron-200: #e4e4e7;
  --Pink-700: #c11574;
  --Pink-200: #fcceee;
  --Pink-50: #fdf2fa;
  --success-700: #067647;
  --success-200: #abefc6;
  --success-50: #ecfdf3;
  --warning-700: #b54708;
  --warning-600: #dc6803;
  --warning-400: #fdb022;
  --warning-300: #fec84b;
  --warning-200: #fedf89;
  --warning-50: #fffaeb;
  --warning-25: #fffcf5;
  --Error-700: #b42318;
  --Error-600: #d92d20;
  --Error-500: #f04438;
  --Error-300: #fda29b;
  --Error-200: #fecdca;
  --Error-50: #fef3f2;
  --success-600: #079455;
  --success-500: #17b26a;

  --Shadow-xs: 0px 1px 2px rgba(16, 24, 40, 0.05);

  /* เวลาจะเปลี่ยนสี หรือใช้สีต่างๆ ให้นำจากชุดข้อมูลข้างล่างนี้ มาใช้ หากไม่มีก็ให้เพิ่ม */
  /* สีตอนกรอกข้อมูลในช่อง text ต่างๆ */
  --fill-color: #101828; 
  
  /* กลุ่มชุดดีฟอลของ WEB SIDE */
  --Base-Font-Color: #475467;
  --Base-Font-Focus-Color: #A81E23;
  --Base-Font-Title-Color: #A81E23;
  --Base-Font-Title-SUB-Color: #182230;
  --COE-Main-CI: #A81E23;

  /* TABLE */
  --BADGE-Table-Font: #B42318;
  --BADGE-Table-Border: #FECDCA;
  --BADGE-Table-Bg: #FEF3F2;

  --Table-Bg-Header: #ffffff;
  --Td-Font-Color-Link: #475467;
  --Button-Pagination-Next: #ffffff;
  --Button-Pagination-Previous: #475467;
  --Table-Font-Default: #475467;
  --Table-Bg-Default: #ffffff;
  --Table-Font-Hover: #344054;
  --Table-Bg-Hover: #f9fafb;
  --Table-Action-Edit-Font: #6941c6;

  /* MENU */
  --Side-Menu-Bg: #A81E23;
  --Side-Menu-Icon: #FDA29B;
  --Side-Menu-Icon-Select-Bg: #D92D20;
  --Side-Menu-Icon-Select: #FEE4E2;

  --Side-Menu-Open-Bg-Default: #912018;
  --Side-Menu-Open-Font-Default: #F4EBFF;
  --Side-Menu-Open-Bg-Active: #D92D20;
  --Side-Menu-Open-Font-Active: #FFFFFF;
  --Side-Menu-Open-Bg-Hover: #D92D20;
  /* BADGE STATUS */
  --Overdue-Badge-Font: #b42318;
  --Overdue-Badge-Stroke: #d92d20;
  --Doing-Badge-Font: #6941c6;
  --Doing-Badge-Stroke: #7f56d9;
  --Approved-Badge-Font: #067647;
  --Approved-Badge-Stroke: #abefc6;
  --Approved-Badge-Bg: #ecfdf3;
  --Rejected-Badge-Font: #b54708;
  --Rejected-Badge-Stroke: #fedf89;
  --Rejected-Badge-Bg: #fffaeb;
  --Status-Icon-Font: #344054;
  --Status-Icon-Stroke: #d0d5dd;
  --Status-Icon-Bg: #ffffff;
  --Status-Active: #17b26a;
  --Status-Inactive: #f04438;
  --Status-Draft: #667085;
  /* TAB */
  --Tab-Bg-Current: #ffffff;
  --Tab-Font-Current: #A81E23;
  --Tab-Border-Current: #A81E23;
  --Tab-Bg-Default: #ffffff;
  --Tab-Border-Default: #EAECF0;
  --Tab-Font-Default: #667085;
  /* BUTTON */
  --Main-Button-Bg: #A81E23; 
  --Main-Button-Font: #ffffff;
  --Main-Button-Bg-Hover: #A81E23;
  --Main-Button-Font-Hover: #ffffff;

  --Secondary-Button-Bg: #ffffff;
  --Secondary-Button-Font: #344054;
  --Secondary-Button-Stroke: #d0d5dd;
  --Secondary-Button-Bg-Hover: #f9fafb;
  --Secondary-Button-Font-Hover: #6941c6;
  --Secondary-Button-Stroke-Hover: #f9f5ff;

  --Tertiary-Gray-Button-Bg: #ffffff;
  --Tertiary-Gray-Button-Font: #344054;
  --Tertiary-Gray-Button-Stroke: #A81E23;
  --Tertiary-Gray-Button-Hover: #f9fafb;
  --Tertiary-Color-Button-Font: #6941c6;
  --Tertiary-Color-Button-Bg-Hover: #f9f5ff;
  
  --Download-Button-Font: #475467;
  /* CHECKBOX & RADIO */
  --Checkbox-Unchecked-Stroke: #d0d5dd;
  --Checkbox-Unchecked-Bg: #ffffff;
  --Checkbox-Checked-Stroke: #A81E23;
  --Checkbox-Checked-Bg: #f9f5ff;

  --Radio-Checked: #A81E23;

  /* BADGE FILTER */
  --Badge-Filter-Bg: #f9f5ff;
  --Badge-Filter-Font: #6941c6;
  --Badge-Filter-Stroke: #e9d7fe;
  --Badge-Filter-Close: #9e77ed;
  /* DASHBOARD */
  --Dashboard-Icon: #7f56d9;
  --Dashboard-Icon-Stroke: #eaecf0;
  --Dashboard-Icon-Bg: #ffffff;
  /* UPLOAD */
  --Loding-Bar: #7f56d9;
  /* DATE FORMAT & CALENDAR */
  --Main-Button-Bg-Calendar: #7f56d9;
  --Date-Selection-Bg: #7f56d9;
  /* MODAL (confirm,success,error,warning) */
  --Modal-Confirm-Icon: #7f56d9;
  --Modal-Confirm-Icon-Inner-Layer: #f4ebff;
  --Modal-Confirm-Icon-Outer-Layer: #f9f5ff;
  --Modal-Confirm-Button: #7f56d9;

  --Modal-Delete-Confirm-Icon: #d92d20;
  --Modal-Delete-Confirm-Icon-Inner-Layer: #fee4e2;
  --Modal-Delete-Confirm-Icon-Outer-Layer: #fef3f2;
  --Modal-Delete-Confirm-Button: #d92d20;

  --Modal-Success-Confirm-Icon: #079455;
  --Modal-Success-Confirm-Icon-Inner-Layer: #dcfae6;
  --Modal-Success-Confirm-Icon-Outer-Layer: #ecfdf3;
  --Modal-Success-Confirm-Button: #079455;
  /* LANDING PAGE DETAIL */
  --Header-Bg: #7f56d9;
  --Progress-Bar: #7f56d9;
  --Highlight-Text: #6941c6;
  /* CHART */
  --Chart-Color-1: #7f56d9;
  --Chart-Color-2: #d6bbfb;
  --Chart-Color-3: #f4ebff;
  /* STEPPER */
  --Stepper-Icon: #7f56d9;
  --Stepper-Inner-Bg: #f9f5ff;
  --Stepper-Outer-Bg: #f4ebff;
  --Stepper-Line: #7f56d9;
  /* BADGE COLOR LIST*/
  --BADGE-1-Font: #6941c6;
  --BADGE-1-Border: #e9d7fe;
  --BADGE-1-Bg: #f9f5ff;

  --BADGE-2-Font: #b42318;
  --BADGE-2-Border: #fecdca;
  --BADGE-2-Bg: #fef3f2;

  --BADGE-3-Font: #b54708;
  --BADGE-3-Border: #fedf89;
  --BADGE-3-Bg: #fffaeb;

  --BADGE-4-Font: #067647;
  --BADGE-4-Border: #abefc6;
  --BADGE-4-Bg: #ecfdf3;

  --BADGE-5-Font: #0086c9;
  --BADGE-5-Border: #b9e6fe;
  --BADGE-5-Bg: #f0f9ff;

  --BADGE-6-Font: #004eeb;
  --BADGE-6-Border: #b2ccff;
  --BADGE-6-Bg: #eff4ff;

  --BADGE-7-Font: #9f1ab1;
  --BADGE-7-Border: #f6d0fe;
  --BADGE-7-Bg: #fdf4ff;

  --BADGE-8-Font: #e62e05;
  --BADGE-8-Border: #ffd6ae;
  --BADGE-8-Bg: #fff4ed;

  --BADGE-9-Font: #363f72;
  --BADGE-9-Border: #d5d9eb;
  --BADGE-9-Bg: #f8f9fc;

  --BADGE-10-Font: #3b7c0f;
  --BADGE-10-Border: #d0f8ab;
  --BADGE-10-Bg: #f3fee7;

  --BADGE-11-Font: #3538cd;
  --BADGE-11-Border: #c7d7fe;
  --BADGE-11-Bg: #eef4ff;

  --BADGE-12-Font: #c11574;
  --BADGE-12-Border: #fcceee;
  --BADGE-12-Bg: #fdf2fa;

  --BADGE-13-Font: #ca8504;
  --BADGE-13-Border: #feee95;
  --BADGE-13-Bg: #fefbe8;

  --BADGE-14-Font: #107569;
  --BADGE-14-Border: #99f6e0;
  --BADGE-14-Bg: #f0fdf9;

  --BADGE-15-Font: #5925dc;
  --BADGE-15-Border: #d9d6fe;
  --BADGE-15-Bg: #f4f3ff;

  --BADGE-16-Font: #44403c;
  --BADGE-16-Border: #e7e5e4;
  --BADGE-16-Bg: #f5f5f4;

  /* OTP */
  --OTP-FONT: #475467;
  --OTP-FONT-PHONE: #A81E23;
  --OTP-INPUT-FONT: #A81E23;
  --OTP-INPUT-BORDER: #A81E23;

  /* MenuBoxNotification */
  --BOX-NOTIFICATION-FONT: #A81E23;
  --BOX-NOTIFICATION-BG: #FFFFFF;
  --BOX-NOTIFICATION-BORDER: #A81E23;
`

const darkTheme = css`
  /* --Base-White: #fff; */
`

const StyledTheme = createGlobalStyle`
  :root {
    ${lightThemeA81E23}
  }

`

export default StyledTheme
