import axios from 'axios'

import { BASE_API } from '../configs/app'
import { getAdminToken, removeAdminData } from './common'

const fetchApi = async (options = {}, doubleBearer = false) => {
  const { url, headers, ...restOptions } = options
  const token = getAdminToken()
  const defaultOptions = {
    baseURL: BASE_API,
    /*timeout: 60000,*/
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    data: {},
    params: {},
  }

  if (token) {
    defaultOptions.headers.Authorization = `Bearer ${token}`
    if (doubleBearer) {
      defaultOptions.headers.Authorization = 'Bearer ' + defaultOptions.headers.Authorization
    }
  }

  defaultOptions.headers = {
    ...defaultOptions.headers,
    ...headers,
  }

  try {
    const { data } = await axios.request({
      url,
      ...defaultOptions,
      ...restOptions,
    })

    return data
  } catch (error) {
    if (error.response?.status === 401) {
      removeAdminData()
      window.location.href = '/login'
      return null
    }else if(error.code == 'ERR_NETWORK'){ //---- กรณีอื่นๆ เช่น network error หรือต่อ Api ไม่ได้
      removeAdminData()
      window.location.href = '/Maintenance'
      return null
    }
    return Promise.reject(error)
  }
}

export const getAPI = (options = {}, ...arg) => fetchApi(options, ...arg)

export const postAPI = (options = {}, ...arg) =>
  fetchApi(
    {
      ...options,
      method: 'POST',
    },
    ...arg
  )

export const putAPI = async (options = {}, ...arg) =>
  fetchApi(
    {
      ...options,
      method: 'PUT',
    },
    ...arg
  )

export const patchAPI = async (options = {}, ...arg) =>
  fetchApi(
    {
      ...options,
      method: 'PATCH',
    },
    ...arg
  )

export const deleteAPI = async (options = {}, ...arg) =>
  fetchApi(
    {
      ...options,
      method: 'DELETE',
    },
    ...arg
  )
