import { getAPI, postAPI,patchAPI } from '../../utils/api'
import { 
    GET_elearning,
    POST_elearning,
    UPDATE_elearning
} from '../actionTypes'
import { showLoading, hideLoading } from './loading'

export const Api_Post_OrgElearning = (data) => async (dispatch) => {
    try {
      
      dispatch(showLoading())
      dispatch({ type: POST_elearning.REQUEST })
      const accessToken = localStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
      
      const data_result = await  postAPI({
        url: `/api/elearning/OrgElearning`,
        data,
        headers: headers,
      })
      
      return { type: 'Api_Post_OrgElearning_SUCCESS', data_info: data_result }

    } catch (err) {
      return { type: 'Api_Post_OrgElearning_ERROR' }

    } finally {
      dispatch(hideLoading())
    }
}

export const Api_org_num_status_elearning = (data) => async (dispatch) => {
    try {
      
      dispatch(showLoading())
      dispatch({ type: POST_elearning.REQUEST })
      const accessToken = localStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
      
      const data_result = await  postAPI({
        url: `/api/elearning/org_num_status_elearning`,
        data,
        headers: headers,
      })
      
      return { type: 'Api_org_num_status_elearning_SUCCESS', data_info: data_result }

    } catch (err) {
      return { type: 'Api_org_num_status_elearning_ERROR' }

    } finally {
      dispatch(hideLoading())
    }
}

export const Api_org_vw_elearning = (filter = { limit: 10, skip: 0 }) => async (dispatch) => {
    try {
      
      dispatch(showLoading())
      dispatch({ type: GET_elearning.REQUEST })

      const accessToken = localStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
      let params = {
        '$limit': filter.limit,
        '$skip': filter.skip,
      }

      if(filter.status_id){
        params = {...params,'status_id': filter.status_id}
      }

      if(filter.name){
        params = {...params,'topic_name[$like]': `%${filter.name}%`}
      }

      if(filter.user){
        params = {...params,'created_by': filter.user}
      }

      if(filter.date){
        params = {...params,'createdAt[$gte]': `${filter.date} 00:00:00` , 'createdAt[$lte]': `${filter.date} 23:59:59`}
      }

      const data_result = await getAPI({
        url: '/api/elearning/org_vw_elearning?$sort[id]=-1',
        headers: headers,
        params,
      })

      return { type: 'Api_org_vw_elearning_SUCCESS', data_info: data_result }

    } catch (err) {
      return { type: 'Api_org_vw_elearning_ERROR' }

    } finally {
      dispatch(hideLoading())
    }
}

export const ApiUpdateOrgElearning = (id, data) => async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: UPDATE_elearning.REQUEST })
      const accessToken = localStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
      const data_result = await patchAPI({
        url: `/api/elearning/OrgElearning/${id}`,
        data,
        headers: headers,
      })
  
      return { type: 'ApiUpdateOrgElearning_SUCCESS', data_info: data_result }

    } catch (err) {

      return { type: 'ApiUpdateOrgElearning_ERROR' }

    } finally {
      dispatch(hideLoading())
    }
}

export const ApiOrgElearning_profile = (data) => async (dispatch) => {
    try {
      
      dispatch(showLoading())
      dispatch({ type: POST_elearning.REQUEST })
      const accessToken = localStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
      
      const data_result = await  postAPI({
        url: `/api/elearning/OrgElearning_profile`,
        data,
        headers: headers,
      })
      
      return { type: 'ApiOrgElearning_profile_SUCCESS', data_info: data_result }

    } catch (err) {
      return { type: 'ApiOrgElearning_profile_ERROR' }

    } finally {
      dispatch(hideLoading())
    }
}

export const Api_get_OrgElearningExam = (filter = { limit: 10, skip: 0 }) => async (dispatch) => {
    try {
      
      dispatch(showLoading())
      dispatch({ type: GET_elearning.REQUEST })

      const accessToken = localStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
      let params = {
        '$limit': filter.limit,
        '$skip': filter.skip,
        'status_id': 1,
      }

      if(filter.elearning_id){
        params = {...params,'elearning_id': filter.elearning_id}
      }

      const data_result = await getAPI({
        url: '/api/elearning/OrgElearningExam?$sort[id]=1',
        headers: headers,
        params,
      })

      return { type: 'Api_get_OrgElearningExam_SUCCESS', data_info: data_result }

    } catch (err) {
      return { type: 'Api_get_OrgElearningExam_ERROR' }

    } finally {
      dispatch(hideLoading())
    }
}

export const Api_Post_OrgElearningExam = (data) => async (dispatch) => {
    try {
      
      dispatch(showLoading())
      dispatch({ type: POST_elearning.REQUEST })
      const accessToken = localStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
      
      const data_result = await  postAPI({
        url: `/api/elearning/OrgElearningExam`,
        data,
        headers: headers,
      })
      
      return { type: 'Api_Post_OrgElearningExam_SUCCESS', data_info: data_result }

    } catch (err) {
      return { type: 'Api_Post_OrgElearningExam_ERROR' }

    } finally {
      dispatch(hideLoading())
    }
}

export const Api_Update_OrgElearningExam = (id, data) => async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: UPDATE_elearning.REQUEST })
      const accessToken = localStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
      const data_result = await patchAPI({
        url: `/api/elearning/OrgElearningExam/${id}`,
        data,
        headers: headers,
      })
  
      return { type: 'Api_Update_OrgElearningExam_SUCCESS', data_info: data_result }

    } catch (err) {

      return { type: 'Api_Update_OrgElearningExam_ERROR' }

    } finally {
      dispatch(hideLoading())
    }
}

export const ApiUpdateStatusElearning = (data) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: POST_elearning.REQUEST })
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    
    const data_result = await  postAPI({
      url: `/api/elearning/UpdateStatusElearning`,
      data,
      headers: headers,
    })
    
    return { type: 'ApiUpdateStatusElearning_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiUpdateStatusElearning_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiEleaningExamEngineer = (data) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: POST_elearning.REQUEST })
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    
    const data_result = await  postAPI({
      url: `/api/elearning/OrgEleaningExamEngineer`,
      data,
      headers: headers,
    })
    
    return { type: 'ApiEleaningExamEngineer_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiEleaningExamEngineer_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiOrgEleaningExamList = (data) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: POST_elearning.REQUEST })
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    
    const data_result = await  postAPI({
      url: `/api/elearning/OrgEleaningExamList`,
      data,
      headers: headers,
    })
    
    return { type: 'ApiOrgEleaningExamList_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiOrgEleaningExamList_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiAddExamFromElerning = (data) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: POST_elearning.REQUEST })
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    
    const data_result = await  postAPI({
      url: `/api/elearning/OrgAddExamFromElerning`,
      data,
      headers: headers,
    })
    
    return { type: 'ApiAddExamFromElerning_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiAddExamFromElerning_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}