import styled from 'styled-components'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { BASE_API_UPLOAD } from '../../configs/app'
import { useTranslation } from 'react-i18next'

import { useDropzone } from 'react-dropzone'


import IconButton from '../common/IconButton'

import iconUpload from '../../assets/images/icon-upload-round.svg'
import iconFile from '../../assets/images/icon-file-round.svg'
import iconBin from '../../assets/images/icon-bin.svg'

import failIcon from '../../assets/images/fail-icon.png'
import DialogFail from '../../components/dialog/DialogFail'

import { DateTimeHHmmssDisplay } from '../../utils/common'

const Div = styled.div`
  .upload-zone {
    padding: 14px;
    color: var(--Gray-600);
    font-size: 14px;
    background: var(--Base-White);
    border: 1px solid ${({ $error }) => ($error ? 'red' : 'var(--Gray-300)')};
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
    text-align: center;
    cursor: pointer;

    img {
      width: 40px;
    }

    p {
      margin: 0;

      b {
        color: var(--Primary-700);
      }
    }
  }

  .upload-list {
    .file {
      width: 100%;
      display: inline-flex;
      padding: 14px;
      margin-top: 0px;
      background: var(--Base-White);
      border: 1px solid var(--Gray-300);
      box-shadow: var(--Shadow-xs);
      border-radius: 8px;
    }

    .icon-wrapper {
      width: 32px;
    }

    .detail-wrapper {
      flex: 1;
      margin: 0 14px;
      font-size: 14px;
      overflow: hidden;

      .file-name {
        margin-bottom: 2px;
        text-overflow: ellipsis;
        overflow: hidden;

        a {
          font-weight: 500;
          color: var(--Gray-700);
        }
      }
    }
  }
`

const DivTable = styled.div`
  width: 100%;
  overflow-x: auto;
  margin-bottom: 20px;
  .text_css {
    min-width: 200px;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: var(--Shadow-xs);

    tr {
      /*border: 1px solid var(--Gray-200);*/
    }

    th,
    td {
      padding: 16px 14px;
      text-align: left;
      font-size: 14px;
      color: var(--Gray-600);
      white-space: nowrap;
      background: var(--Base-White);
    }

    th {
      padding: 12x 14px;
      font-weight: 500;
      background: var(--Gray-50);
    }
    /*
    tr:hover {
      td {
        background: var(--Primary-50);
        color: var(--Primary-700);
      }
    }
    */
  }

  .col-File {
    width: 50%;
    border-bottom: 1px solid var(--Gray-200);
  }
  .col-Timestamp {
    width: 25%;
    border-bottom: 1px solid var(--Gray-200);
  }
  .col-UploadBy {
    width: 25%;
    border-bottom: 1px solid var(--Gray-200);
  }

  .content-wrapper {
    text-align: center;
  }

  .pagination {
    margin: 30px 0;
  }

  .cursor_pointer {
    cursor: pointer;
  }
  .upload-list {
    padding: 0px 0px;
    .file {
      width: 100%;
      display: inline-flex;
      padding: 14px;
      margin-top: 0px;
      background: var(--Base-White);
      border: 1px solid var(--Gray-300);
      box-shadow: var(--Shadow-xs);
      border-radius: 8px;
    }

    .icon-wrapper {
      width: 32px;
    }

    .detail-wrapper {
      flex: 1;
      margin: 0 14px;
      font-size: 14px;
      overflow: hidden;

      .file-name {
        margin-bottom: 2px;
        text-overflow: ellipsis;
        overflow: hidden;

        a {
          font-weight: 500;
          color: var(--Gray-700);
        }
      }
    }
  }
  .upload-list-zone {
    padding: 16px 0px;
    .upload-zone {
      padding: 14px;
      color: var(--Gray-600);
      font-size: 14px;
      background: var(--Base-White);
      border: 1px solid ${({ $error }) => ($error ? 'red' : 'var(--Gray-300)')};
      box-shadow: var(--Shadow-xs);
      border-radius: 8px;
      text-align: center;
      cursor: pointer;

      img {
        width: 40px;
      }

      p {
        margin: 0;

        b {
          color: var(--Primary-700);
        }
      }
    }
  }
  .text_css_error {
    color: red;
  }
`

import axios from 'axios'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'
import { styled as styled_mui } from '@mui/material/styles'
const BorderLinearProgress = styled_mui(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}))

const UploadFileArray = ({
  multiple = false,
  accept = {},
  file = [],
  onAddFile,
  onDeleteFile,
  error = false,
  disabled = false,
  FileListData,
  limitFileSize = 300,
  isOneFile = false,
  indexdata = 0,
  subtext = '',
  ...props
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [TitleFail, setTitleFail] = useState('')
  const [nameItemFail, setnameItemFail] = useState('')
  const [DescriptionFail, setDescriptionFail] = useState('')
  const [failModal, setFailModal] = useState(false)

  const { getRootProps, getInputProps } = useDropzone({
    /*maxFiles: multiple ? 0 : 1,*/
    maxFiles: 1, //--- By James Fix เป็นแบบ File เดี่ยวก่อนเพราะยังไม่ได้เทสแบบหลายๆ File
    accept,
    onDropAccepted: (value) => {
      value.forEach((v, index_data) => {
        const this_index_file_list = FileList.length + (index_data + 1)
        if (parseInt(v.size) > parseInt(limitFileSize * 1024 * 1024)) {
          setTitleFail('ไฟล์มีขนาดใหญ่เกินกว่ากำหนด')
          setnameItemFail('')
          setDescriptionFail('')
          setFailModal(true)
        } else {
          let file_data = {
            key_file_id : null,
            bucketName : null,
            objectName : null,
            file_size : null,
            mime_type : null,
            file_extension : null,
            url: null,
            name: v.name,
            size: v.size,
            progress_percent: 0,
            progress_display: `-`,
            progress_is_error: false,
          }

          const formData = new FormData()
          //formData.append('files', v)
          formData.append('file', v)
          formData.append(
            'fileInfo',
            JSON.stringify({
              name: v.name,
              caption: v.name,
              alternativeText: v.name,
              folder: null,
            })
          )

          const options = {
            onUploadProgress: (progressEvent) => {
              const { loaded, total } = progressEvent
              let percent = Math.floor((loaded * 100) / total)

              if (percent < 100) {
                file_data = {
                  key_file_id : null,
                  bucketName : null,
                  objectName : null,
                  file_size : null,
                  mime_type : null,
                  file_extension : null,
                  url: null,
                  name: v.name,
                  size: v.size,
                  progress_percent: percent,
                  progress_display: `${convertByteToKB(loaded)}KB of ${convertByteToKB(total)}KB | ${percent}%`,
                  progress_is_error: false,
                }
                onAddFile(file_data,indexdata)
              } else {
                file_data = {
                  key_file_id : null,
                  bucketName : null,
                  objectName : null,
                  file_size : null,
                  mime_type : null,
                  file_extension : null,
                  url: null,
                  name: v.name,
                  size: v.size,
                  progress_percent: percent,
                  progress_display: `กำลังรวบรวมไฟล์`,
                  progress_is_error: false,
                }
                onAddFile(file_data,indexdata)
              }
            },
          }

          const data = formData
          axios
            .post(BASE_API_UPLOAD + '/file/uploadformdata', data, options)
            .then((res) => {
              //console.log('console.log(res)')
              //console.log(res.data)
              
              file_data = {
                key_file_id : res.data.key_file_id,
                bucketName : res.data.bucketName,
                objectName : res.data.objectName,
                file_size : res.data.file_size,
                mime_type : res.data.mime_type,
                file_extension : res.data.file_extension,
                url: res.data.url_file,
                name: v.name,
                size: convertByteToKB(v.size),
                progress_percent: 100,
                progress_display: `OK`,
                progress_is_error: false,
              }
              onAddFile(file_data,indexdata)
            })
            .catch((error) => {
              file_data = {
                key_file_id : null,
                bucketName : null,
                objectName : null,
                file_size : null,
                mime_type : null,
                file_extension : null,
                url: null,
                name: v.name,
                size: convertByteToKB(v.size),
                progress_percent: 100,
                progress_display: `ไม่สามารถอัพโหลดไฟล์ได้`,
                progress_is_error: true,
              }
              onAddFile(file_data,indexdata)
            })
        }
      })
    },
    disabled,
  })

  const convertByteToKB = (byte_value) => {
    return (byte_value / 1024).toFixed(2)
  }

  return (
    <Div $error={error} {...props}>
      <DivTable>
          <div>
            {(FileListData.length == 0 && isOneFile) && (
              <div {...getRootProps({ className: 'upload-zone' })}>
                <input {...getInputProps()} />
                <img src={iconUpload} />
                <p>
                  <b>{'คลิกเพื่ออัพโหลด'}</b> {'หรือลากและวาง'}
                </p>
                <p>
                  {subtext}
                </p>
              </div>
            )}
            {(!isOneFile) && (
              <div {...getRootProps({ className: 'upload-zone' })}>
                <input {...getInputProps()} />
                <img src={iconUpload} />
                <p>
                  <b>{'คลิกเพื่ออัพโหลด'}</b> {'หรือลากและวาง'}
                </p>
                <p>
                  {subtext}
                </p>
              </div>
            )}
            <div className="upload-list">
              {FileListData.map((v_file_list) => (
                <div>
                  {v_file_list.url != null && (
                    <div className="file" key={v_file_list.id}>
                      <div className="icon-wrapper">
                        <img src={iconFile} />
                      </div>
                      <div className="detail-wrapper">
                        <div className="file-name">
                          <a href={v_file_list.url} target="_blank" rel="noreferrer">
                            {v_file_list.name}
                          </a>
                        </div>
                        <div className="file-size">{v_file_list.size} KB</div>
                      </div>
                      <div className="action-wrapper">
                        <IconButton src={iconBin} title="Delete" onClick={() => onDeleteFile(v_file_list.key_file_id,indexdata)} />
                      </div>
                    </div>
                  )}
                  {v_file_list.url == null && (
                    <div className="file">
                      <div className="icon-wrapper">
                        <img src={iconFile} />
                      </div>
                      <div className="detail-wrapper">
                        <div className="file-name">{v_file_list.name}</div>
                        <div className="file-progress">
                          <BorderLinearProgress variant="determinate" value={v_file_list.progress_percent} />
                        </div>
                        <div className={v_file_list.progress_is_error ? 'file-size text_css_error' : 'file-size '}>
                          {v_file_list.progress_display}
                        </div>
                      </div>
                      {v_file_list.progress_is_error == true && (
                        <div className="action-wrapper">
                          <IconButton src={iconBin} title="Delete" onClick={() => onDeleteFile(v_file_list.key_file_id,indexdata)} />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
      </DivTable>
      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={() => setFailModal(false)}
        icon={failIcon}
        title={TitleFail}
        nameItem={nameItemFail}
        description={DescriptionFail}
        textYes="Ok"
      />
    </Div>
  )
}

export default UploadFileArray
