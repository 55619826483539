import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

import {  getAdminUser } from '../../utils/common'
import { Api_get_OrgElearningExam,Api_Update_OrgElearningExam,ApiEleaningExamEngineer,ApiOrgEleaningExamList,ApiAddExamFromElerning } from '../../redux/actions/elearning'

import Button from '../../components/form/button/Button'
import ButtonSecondary from '../../components/form/button/ButtonSecondary'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import TableV2 from '../../components/common/TableV2'
import SidePanel from '../../components/common/SidePanel'
import NewQuestion from '../../pages/elearning/NewQuestion'
import EditQuestion from '../../pages/elearning/EditQuestion'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import DialogFail from '../../components/dialog/DialogFail'
import DialogConfirmV2 from '../../components/dialog/DialogConfirmV2'
import Modal from '../../components/common/Modal'

import iconPlus from '../../assets/images/icon-plus.svg'
import { FaBullseye } from 'react-icons/fa'
import { GrStatusGoodSmall } from 'react-icons/gr'
import iconEdit from '../../assets/images/edit-icon.png'
import iconDelete from '../../assets/images/icon-bin-red.svg'
import warningIcon from '../../assets/images/warning-icon.png'
import successIcon from '../../assets/images/success-icon.png'
import failIcon from '../../assets/images/fail-icon.png'

const Div = styled.div`


  .table-wrapper-conten-exam {
    margin-top: 24px;
    border: 1px solid #eaecf0;
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
    background-color: #ffffff;

    .table-header {
      display: flex;
      justify-content: space-between;
      padding: 0px 14px;
      border-radius: 8px 8px 0 0;
      background: var(--Table-Bg-Header);

      .group {
        display: flex;
        align-items: center;
      }

      .table-title {
        font-size: 18px;
        font-weight: 600;
        color: var(--Gray-900);
      }

      .table-total {
        margin-left: 16px;
        font-size: 12px;
        font-weight: 500;
        padding: 2px 8px;
        color: var(--BADGE-Table-Font);
        background: var(--BADGE-Table-Bg);
        border-radius: 16px;
        border: 1px var(--BADGE-Table-Border) solid;
      }
    }

    .create-bt {
      text-decoration: none;
    }
  }
  .css_magin_top_10 {
    margin-top: 10px;
  }
  .css_button_filter{
    display: inline-block;
    align-self: flex-end;
  }
  .group_button {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .table-sub{
    padding: 0px 14px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
  .button_chose_exam{
    color: var(--Base-Font-Focus-Color);
  }
  .question_img{
      img {
        width: 74px;
        height: 40px;
      }
  }
  .status-badge-content-exam {
    width: 90px;
    padding: 0.2rem;
    text-align: center;
    border: 1.5px solid var(--Gray-300);
    border-radius: 6px;
    font-weight: 500;
    background: #FFFFFF;
    

    .status {
      font-size: 0.6rem;
      margin-right: 0.2rem;
      color: #17b26a;
      

      &.deny {
        color: red;
      }
      &.request {
        color: #7f56d9;
      }
      &.pedding{
        color: #475467;
      }
    }
  }
  .cursor_pointer {
    cursor: pointer;
  }
`

import SearchText from '../../components/form/SearchText'
import { RiSearchLine } from 'react-icons/ri'
import { LiaTimesSolid } from 'react-icons/lia'
const DivModalStoreExam = styled.div`
  width: 1123px;
  height: 667px;
  .header_ModalStoreExam{
    padding: 16px 16px;
    .search-input {
      position: relative;
      color: var(--Gray-500);

      .input-field {
        box-sizing: border-box;
        padding: 10px;
        padding-left: 40px;
        border: none;

        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        width : 80%;
        font-family: inherit;
        font-size: 16px;
      }

      .start-icon {
        position: absolute;
        top: 10px;
        left: 10px;
      }

      .end-icon {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
      }
    }
  }

  .border_header {
    border-bottom: 1px solid rgb(234, 236, 240);
  }
  .box_content_store{
    display: grid;
    grid-template-columns: repeat(2, 22% [ col-start ] 77% [ col-end ]);
    gap: 1%;
    height: 89%;
  }
`

const DivContentLeft = styled.div`
padding: 16px 8px;
border-right: 1px solid rgb(234, 236, 240);
overflow: auto;
.text_engineering{
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color : #475467;
  margin-bottom: 8px;
}
.box_engineering{
  padding: 10px 10px;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  gap: 10px;
  cursor: pointer;
  &.active {
    border-radius: 8px;
    background: #F2F4F7;
  }
  .box_text{
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    color : #101828;
  }
  .box_num{
    display: inline-block;
    align-self: center;
    height: 22px;
    font-size: 12px;
    font-weight: 500;
    padding: 2px 8px;
    border-radius: 16px;
    color: #344054;
    background: #F9FAFB;
    border: 1px solid #EAECF0;
  }
}
`

const DivContentRight = styled.div`
padding: 24px 20px;
overflow: auto;
.text_engineering{
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  color: #A91F23;
  margin-bottom: 24px;
}
.border_header {
  border-bottom: 1px solid rgb(234, 236, 240);
}
.box_exam{
  display: flex;
  flex-wrap: wrap;
  min-width: 100%;
  gap : 24px;
  .box_exam_data{
    width : 389px;
    cursor: pointer;
    border-radius: 12px;
    border: 1px solid #EAECF0;
    .box_exam_data_content{
      padding : 24px;
      .text_topic{
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        color: #101828;
        min-height: 48px;
      }
      .text_by{
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: #475467;
      }
      .box_num_question{
        margin-top: 24px;

        width: fit-content;
        padding: 2px 6px;
        text-align: left;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        border-radius: 16px;
        color: #344054;
        background: #F2F4F7;
        border: 1px #F2F4F7 solid;
      }
    }
    .box_exam_data_footer{
      padding : 0px 24px;
      margin-top: 8px;
      margin-bottom: 12px;
      display: flex;
      -webkit-box-pack: justify;
      justify-content: space-between;
      align-items: center;
      .text_view_exam{
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        color : #475467;
      }
    }
  }
}
`


const ContentExam = ({ elearning_id,engineering_fill,...props }) => {
  const dispatch = useDispatch()
  const user = getAdminUser()

  const [QuestionId, setQuestionId] = useState(null)

  const initMessageModal = {
    headline: '',
    message: '',
  }
  const [ModalConfirmDialog, setModalConfirmDialog] = useState(false)
  const [ModalConfirmDialogDel, setModalConfirmDialogDel] = useState(false)
  const [ConfirmMessageModal, setConfirmMessageModal] = useState(initMessageModal)

  const [successModal, setSuccessModal] = useState(false)
  const [failModal, setFailModal] = useState(false)
  const [successMessageModal, setSuccessMessageModal] = useState(initMessageModal)
  const [failMessageModal, setFailMessageModal] = useState(initMessageModal)

  const regex = /(<([^>]+)>)/gi;
  const limit_data = 10;
  const [total, settotal] = useState(0)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [TableCellData, setTableCellData] = useState([])
  const TableColumnData = [
    {
      Header: 'ลำดับ',
      accessor: 'no',
      disableSortBy: false,
    },
    {
        Header: 'โจทย์',
        accessor: 'question',
        disableSortBy: false,
    },
    {
        Header: 'รูปโจทย์',
        accessor: 'question_img',
        disableSortBy: false,
    },
    {
        Header: 'ออกข้อสอบโดย',
        accessor: 'create_by_display',
        disableSortBy: false,
    },
    {
        Header: 'Status',
        accessor: 'status_name',
        disableSortBy: false,
    },
    {
        accessor: 'buttonDelete',
        disableSortBy: false,
    },
    {
        accessor: 'linkEdit',
        disableSortBy: false,
    },
  ]

  const fetchData = useCallback(
    async (filter) => {

      dispatch(Api_get_OrgElearningExam(filter)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
          if (data_info.total > 0) {
            const totalPage = Math.ceil(data_info.total / limit_data)
            settotal(data_info.total)
            setTotalPages(totalPage)

            const newTableCellData = data_info.data.map((v, index) => ({
              no: limit_data * page - (9 - index),
              question: v.question ? v.question.replace(regex, " ").length > 100 ? v.question.replace(regex, " ").substring(0,100) + '...' : v.question.replace(regex, " ") : '-',
              question_img: (
                  v.question_img ? <div className="question_img">
                    <img src={v.question_img} />
                  </div> : <div >-</div>
              ),
              create_by_display: v.create_by_display ? v.create_by_display : '-',
              status_name: (
                  <div className="status-badge-content-exam">
                    <GrStatusGoodSmall className={`status ${v.status_id == 2 ? 'deny' : ''}`} />
                    {v.status_id == 1 ? 'Active' : 'InActive'}
                  </div>
              ),
              buttonDelete: <img className="cursor_pointer" src={iconDelete} title="Delete" onClick={() => DelData(v)} />,
              linkEdit: <img className="cursor_pointer" src={iconEdit} title="Edit" onClick={() => EditData(v)} />,
            }))
            setTableCellData(newTableCellData)
          }else{
            settotal(0);
            setPage(0);
            setTotalPages(0);
            setTableCellData([]);
          }
         
        }
      })
    },
    [dispatch]
  )

  useEffect(() => {
    const filter = {
      skip: 0,
      limit: limit_data,
      elearning_id: elearning_id,
    }
    fetchData(filter)
  }, [fetchData, limit_data])


  const onChangePaginationAttendeeAttend = async (value) => {
    const filter = {
      skip: (value.page  - 1) * limit_data, 
      limit: limit_data,
      elearning_id: elearning_id,
    }
    setPage(value.page)
  }

  const [WidthSidePanel, setWidthSidePanel] = useState(window.innerWidth - 92)
  const [ModalNewQuestion, setModalNewQuestion] = useState(false)
  const [ModalEditQuestion, setModalEditQuestion] = useState(false)
  const [DataEdit, setDataEdit] = useState(null)
  const [ModalStoreQuestion, setModalStoreQuestion] = useState(false)
  const [filterNameExam, setfilterNameExam] = useState('')
  const [StoreExamEngineer, setStoreExamEngineer] = useState([])

  const [DataExamOfEngineer, setDataExamOfEngineer] = useState([])

  const [ExamEngineer, setExamEngineer] = useState({
    engineering_course_id : null,
    engineer_text : '',
  })

  const OpenModalNewQuestion = () => {
    setModalNewQuestion(true)
  }

  const OpenModalStoreQuestion = () => {
    const sent_data_to_api = {
      engineeringFill : engineering_fill,
    };
    dispatch(ApiEleaningExamEngineer(sent_data_to_api)).then(({ type,data_info }) => {
      if(type.endsWith('_SUCCESS')){        
        if(data_info.code == 200){
          setStoreExamEngineer(data_info.StoreExamEngineer);
        }else{
          setStoreExamEngineer([]);
        }
        setModalStoreQuestion(true)
      }
    })
    
  }

  const CloseModalStoreQuestion = () => {
    setModalStoreQuestion(false)
  }

  const ApiGetDataExam = (ObjData) => {
    setExamEngineer(ObjData)
    const sent_data_to_api = {
      ExamEngineer : ObjData.engineering_course_id,
    };
    dispatch(ApiOrgEleaningExamList(sent_data_to_api)).then(({ type,data_info }) => {
      if(type.endsWith('_SUCCESS')){        
        if(data_info.code == 200){
          setDataExamOfEngineer(data_info.StoreExamEngineer);
        }else{
          setDataExamOfEngineer([]);
        }
      }
    })
  }

  const ProcessApiAddExamFromElerning = (ObjData) => {
    //alert(JSON.stringify(ObjData))
    const sent_data_to_api = {
      elearning_id : elearning_id,
      exam_elearning_id : ObjData.elearning_id,
    };
    dispatch(ApiAddExamFromElerning(sent_data_to_api)).then(({ type,data_info }) => {
      if(type.endsWith('_SUCCESS')){        
        onClearSearch();
        setExamEngineer({
          engineering_course_id : null,
          engineer_text : '',
        });
        setModalStoreQuestion(false);
      }
    })

  }

  const onClearfilterNameExam = () => {
    setfilterNameExam('');
  }

  const onClearSearch = () => {
    const filter = {
      skip: 0,
      limit: limit_data,
      elearning_id: elearning_id,
    }
    fetchData(filter)
    
    setPage(1)
  }

  const onSubmitQuestion = () => {
    onClearSearch();
    setModalNewQuestion(false)
    setModalEditQuestion(false)
    //setDataEdit(null)
    
  }

  const DelData = (data) => {
    setQuestionId(data.id)
    setConfirmMessageModal({
        headline: 'กรุณายืนยันการลบโจทย์นี้',
        message: '',
    })
    setModalConfirmDialogDel(true);
  }
  const EditData = (data) => {
    setDataEdit(data)
    setModalEditQuestion(true)
    //alert(data.id)
  }

  const handleProcess = (type_status_id) => {
    setModalConfirmDialog(false)
    const request = {
      status_id : type_status_id
    }
    dispatch(Api_Update_OrgElearningExam(QuestionId,request)).then(({ type,data_info }) => {
      
      if(type.endsWith('_SUCCESS')){
        if(data_info.id){
          setSuccessMessageModal({
            headline: 'ลบรายการสำเร็จ',
            message: '',
          })
          setSuccessModal(true)
        }else{
          
            setFailMessageModal({
                headline: data_info.message,
                message: '',
            })
            setFailModal(true)
          
        }
      }else{
        setFailMessageModal({
          headline: 'internal server error',
          message: '',
        })
        setFailModal(true)
      }
      
    })  
  }

  const filterStoreExam = (event) => {
    event.preventDefault()
    alert('GOGO API')
  }


  return (
    <Div>

        <div className="table-wrapper-conten-exam">
          <div className="css_magin_top_10"></div>
          <div className="table-header">
            <div className="group">
              <div className="table-title">
                จำนวนคำถามทั้งหมด
              </div>
              <div className="table-total">
                {total} ข้อ
              </div>
            </div>
            <div className="css_button_filter">
                <div className="group_button">
                    <Button  append={<img src={iconPlus} />} onClick={OpenModalNewQuestion}>
                        เพิ่มคำถามใหม่
                    </Button>
                    <ButtonOutline  className="button_chose_exam" onClick={OpenModalStoreQuestion}>
                        เลือกชุดข้อสอบจากคลัง
                    </ButtonOutline>
                </div>

            </div>
          </div>
          <div className="table-sub">* คุณสามารถเลือกจากชุดข้อสอบเดิมที่เคยสร้างไว้ หรือ สร้างใหม่ได้</div>
          <div className="css_magin_top_10">
            <TableV2
              columns={TableColumnData}
              data={TableCellData}
              onStateChange={onChangePaginationAttendeeAttend}
              pageCount={totalPages}
              page={page}
              loading={false}
            />
          </div>
        </div>

        <SidePanel isOpen={ModalNewQuestion} setIsOpen={setModalNewQuestion} width={WidthSidePanel}>
            <NewQuestion 
              width={WidthSidePanel} 
              elearning_id={elearning_id}
              onClose={() => setModalNewQuestion(false)} 
              onSubmit={onSubmitQuestion}
            />
        </SidePanel>
        <SidePanel isOpen={ModalEditQuestion} setIsOpen={setModalEditQuestion} width={WidthSidePanel}>
            <EditQuestion 
              width={WidthSidePanel} 
              DataEdit={DataEdit}
              onClose={() => setModalEditQuestion(false)} 
              onSubmit={onSubmitQuestion}
            />
        </SidePanel>

      <DialogConfirmV2
        open={Boolean(ModalConfirmDialogDel)}
        onClose={() => setModalConfirmDialogDel(false)}
        onNo={() => setModalConfirmDialogDel(false)}
        onSubmit={() => {
          setModalConfirmDialogDel(false)
          handleProcess(0)
        }}
        icon={warningIcon}
        title={ConfirmMessageModal.headline}
        nameItem={ConfirmMessageModal.message}
        textYes='ยืนยัน'
        textNo='ยกเลิก'
      />

      {/* Dialog */}
      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => setSuccessModal(false)}
        onSubmit={() => {
          setSuccessModal(false)
          onClearSearch()
        }}
        icon={successIcon}
        title={successMessageModal.headline}
        description={successMessageModal.message}
        textYes='ตกลง'
      />
      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={() => {
          setFailModal(false)
          onClearSearch()
        }}
        icon={failIcon}
        title={failMessageModal.headline}
        description={failMessageModal.message}
        textYes='ตกลง'
      />

      <Modal open={ModalStoreQuestion} disableBackdropClick>
        <DivModalStoreExam>
          <div className="header_ModalStoreExam">
            <div className="search-input">
              <RiSearchLine className="start-icon" />
              <form onSubmit={filterStoreExam}>
                <input
                  className="input-field"
                  placeholder={'ค้นหาชุดข้อสอบ'}
                  value={filterNameExam}
                  onChange={(e) => setfilterNameExam(e.target.value)}
                />
              </form>
              <LiaTimesSolid size={25} onClick={CloseModalStoreQuestion} className="end-icon icon" />
              </div>
          </div>
          <div className="border_header"></div>
          <div className="box_content_store">
            <DivContentLeft>
              <div>
                <div className="text_engineering">
                  หมวดหมู่ของคุณ   
                </div>
                {StoreExamEngineer?.map((item_store, index_store) => (
                  <div className={`box_engineering ${ExamEngineer.engineering_course_id == item_store.engineering_course_id ? 'active' : ''}`} onClick={() => ApiGetDataExam(item_store)}>
                    <div className="box_text">
                      {item_store.engineer_text}
                    </div>
                    <div className="box_num">
                      {item_store.num_exan}
                    </div>
                  </div>
                ))}
              </div>
            </DivContentLeft>
            <DivContentRight>
              <div>
                <div className="text_engineering">
                  {ExamEngineer.engineer_text}
                </div>
                <div className="box_exam">
                  {DataExamOfEngineer?.map((item_exam, index_exam) => (
                    <div className="box_exam_data">
                      <div className="box_exam_data_content">
                        <div className="text_topic">
                          {item_exam.topic_name}
                        </div>
                        <div className="text_by">
                          โดย {item_exam.elearning_by}
                        </div>
                        <div className="box_num_question">
                        {item_exam.num_question} ข้อ
                        </div>
                      </div>
                      <div className="border_header"></div>
                      <div className="box_exam_data_footer">
                        <div className="text_view_exam">
                          ดูข้อสอบ
                        </div>
                        <div>
                          <Button onClick={() => ProcessApiAddExamFromElerning(item_exam)}>
                              เลือก
                          </Button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </DivContentRight>
          </div>
        </DivModalStoreExam>
      </Modal>  
    </Div>
  )
}

export default ContentExam
