const hostname = window.location.hostname
export const VERSION = '4.0.0.1'
export const BASE_API = process.env.REACT_APP_BASE_API ? process.env.REACT_APP_BASE_API :
hostname == 'cpdorgv2.coe.or.th'? 'https://cpdapiv2.coe.or.th'  : 'https://cpdapiuat.coe.or.th'
export const BASE_API_UPLOAD = process.env.REACT_APP_BASE_API_UPLOAD ? process.env.REACT_APP_BASE_API_UPLOAD : 'https://service-uploads.coe.or.th'

export const BASE_API_OFM = process.env.REACT_APP_BASE_API_OFM
export const BASE_API_TEAM = process.env.REACT_APP_BASE_API_TEAM
export const BASE_WEB_SOCKET = 'ws://127.0.0.1:8000'
export const BASE_LOG_API = ''
export const UAT_API_TOKEN =
  '2e2f8010bbf829b64a21c33b2966ba4144e76d4bd7dd1b0b748ffd7de49bd5d25a2c1cccb1fddda5044376d22cd5db1dd541ab9719b4ce3ba40bba3f000e0fa12ff4aeb4afe9639a394e31a60fc0c0fb54e8acc7273bb1deed426179e24fa8098214d4e51ac9f34bc1665a9b6add402f3ca4ea0de065260fb67c8ae30c001b69'
export const DEV_API_TOKEN =
  '72bb3c17047b92539f5c45b44e572256775f533895305208d75acd85330fb0232cd8b260b7dbc61ef65fcf68bb9bfa656ec1fb25e27a0cbd4a5da92b5405af40a605cab6c8a841d8cb3d794c33f3f34d75b87b0fbbdee521c23787f00df12f0f8fb3c93262958367b245aff00919352e42766e5fd479a597bd8ab8ecdf399d32'
